import styled from "styled-components";
import { Color, Points, Radius, Font } from "../../style_config/VarStyle";
import { Input } from "../../style_config/UseStyle";
import { Link } from "react-router-dom";

const Style = {
  Data: styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 60%;
    border-radius: ${Radius};
    margin: 0 auto;
    padding: 1rem;
    display: grid;
    grid-auto-flow: row;

    @media screen and (max-width: ${Points.smallScreen}) {
      width: 95%;
    }
  `,
  Title: styled.h1`
    font-size: ${({ fs }) => (fs ? fs : Font.size.h1_font_size)};
    font-weight: ${({ fw }) => (fw ? fw : Font.weight.font_regular)};
    word-spacing: ${({ ws }) => (ws ? ws : "")};
    text-transform: ${({ cas }) =>
      cas === 1
        ? "uppercase"
        : cas === 2
        ? "lowercase"
        : cas === 3
        ? "capitalize"
        : ""};
    text-align: ${({ talign }) => (talign ? talign : "")};
    background: ${({ bg }) => (bg ? bg : "")};
    background-clip: ${({ grad }) => (grad ? "text" : "")};
    -webkit-background-clip: ${({ grad }) => (grad ? "text" : "")};
    color: ${({ grad, tcolor }) =>
      grad ? "transparent" : tcolor ? tcolor : ""};
    height: ${({ h }) => (h ? h : "")};
    width: ${({ w }) => (w ? w : "")};
    margin-bottom: 1rem;
  `,
  Button: styled.button`
    padding-top: ${({ pt }) => (pt ? pt + "rem" : ".5rem")};
    padding-bottom: ${({ pb }) => (pb ? pb + "rem" : ".5rem")};
    padding-left: ${({ pl }) => (pl ? pl + "rem" : "1.5rem")};
    padding-right: ${({ pr }) => (pr ? pr + "rem" : "1.5rem")};
    font-size: 0.9rem;
    font-weight: bold;
    width: fit-content;
    background: none;
    outline: none;
    border: 2px solid ${Color.container_color};
    border-radius: ${Radius};
    color: ${Color.container_color};
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    &::before {
      content: "";
      background: ${Color.container_color};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 300%;
      height: 0%;
      transition: 0.2s all ease-in;
      z-index: -1;
    }

    &:hover:before {
      height: 500%;
    }
    &:hover {
      color: ${Color.first_color};
      border: 2px solid transparent;
    }
  `,
  Infield: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    & ${Input} {
      padding-right: 2.1rem !important;
    }
    & svg {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-70%);
      width: 20px;
      height: 20px;
      color: ${Color.text_color};
      cursor: pointer;
    }
  `,
  Text: styled.p`
    text-align: center;
    margin-top: 1.5rem;
  `,
  Link: styled(Link)`
    background: ${({ bg }) => (bg ? bg : "")};
    background-clip: ${({ grad }) => (grad ? "text" : "")};
    -webkit-background-clip: ${({ grad }) => (grad ? "text" : "")};
    color: ${({ grad, tcolor }) =>
      grad ? "transparent" : tcolor ? tcolor : ""};
    text-decoration-style: solid;
    text-decoration-line: underline;
    text-decoration-color: ${Color.first_color};
  `,
  FlexDisplay: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    & div,
    & label {
      cursor: ${({ cursorPointer }) => (cursorPointer ? "pointer" : null)};
    }
  `,
  Select: styled.div`
    height: 64px;
    width: 100%;
    overflow-y: scroll;
    border-radius: ${Radius};
    border: none;
    background-color: ${Color.input_color};
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 2px;

    &:focus,
    &:focus-visible {
      outline: 2px solid ${Color.blue_color};
    }
  `,
  Option: styled.div`
    background-color: ${({ checked }) =>
      checked ? Color.first_color : "transparent"};
    color: ${({ checked }) => (checked ? "white " : "null")};
    padding: 1px 0.5rem;
    cursor: pointer;
  `,
};

export default Style;
