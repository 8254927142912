import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Section } from "../../../style_config/UseStyle";
import {
  Color,
  Font,
  Header_height,
  Points,
  Radius,
} from "../../../style_config/VarStyle";

export default {
  Articles: styled(Section)`
    margin-top: ${Header_height};
    height: fit-content;
    min-height: 100vh;
    background: ${Color.bg_color};
    padding-top: 1rem;
  `,
  Title: styled.h1`
    color: ${({ txt_color }) =>
      txt_color === "gradient"
        ? "transparent"
        : txt_color
        ? txt_color
        : Color.title_color};
    ${({ txt_color }) =>
      txt_color === "gradient"
        ? `background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;`
        : ""}
    background-image: ${({ cat, txt_color }) =>
      txt_color === "gradient"
        ? cat === "true"
          ? "linear-gradient(45deg," + Color.first_color + ",hsl(112,100%,42%))"
          : "linear-gradient(45deg," +
            Color.first_color_alt +
            ", hsl(42,100%,50%))"
        : ""};
    background-color: ${({ bgc }) => (bgc ? bgc : "")};

    font-size: ${({ size }) => (size ? size : Font.size.big_font_size)};
    text-transform: ${({ txt_case }) =>
      txt_case === 1
        ? "lowercase"
        : txt_case === 2
        ? "capitalize"
        : txt_case === 3
        ? "uppercase"
        : "none"};
    line-height: ${({ lh }) => (lh ? "1.5rem" : "2.5rem")};
    padding: ${({ pad }) => (pad ? pad + "rem" : "")};
    text-align: ${({ txt_align }) => (txt_align ? txt_align : "")};
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
    box-shadow: 0 2px 3px
      ${({ shadow_box_color }) =>
        shadow_box_color ? "rgba(0, 0, 0, 0.1)" : "transparent"};
  `,
  Text: styled.p`
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
    font-weight: ${({ fw }) => (fw ? fw : "")};
    color: ${({ txt_color }) => (txt_color ? txt_color : Color.title_color)};
  `,
  GridLayout: styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas: "opt0 opt1";
    margin: 1rem 0;
    grid-gap: 1rem;

    @media screen and (max-width: ${Points.mobile}) {
      grid-template-columns: 1fr;
      grid-template-areas: "opt1" "opt0";
    }
  `,
  GridLeft: styled.div`
    grid-area: opt0;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  `,
  Data: styled(Link)`
    background-color: ${Color.container_color};
    padding: 1rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    border-top-right-radius: ${Radius};
    border-bottom-right-radius: ${Radius};
    position: relative;

    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};

    &::after {
      content: "";
      background: linear-gradient(
        45deg,
        ${({ cat }) =>
          cat === "true" ? Color.first_color : Color.first_color_alt},
        ${({ cat }) =>
          cat === "true" ? "hsl(112,100%,42%)" : "hsl(42,100%,50%)"}
      );
      position: absolute;
      left: -5px;
      top: 0;
      width: 5px;
      height: 100%;
      border-top-left-radius: ${Radius};
      border-bottom-left-radius: ${Radius};
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
      transition: all 0.2s linear;
    }

    &:hover {
      background-color: rgb(248, 248, 248);

      &::after {
        background: ${Color.title_color};
      }
    }
  `,
  GridRight: styled.div`
    grid-area: opt1;
    padding: 1rem;
    background-color: ${Color.container_color};
    height: min-content;
    width: min-content;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: ${Radius};
    height: fit-content;
    max-width: 300px;
    width: fit-content;
    min-width: 200px;

    @media screen and (max-width: ${Points.mobile}) {
      margin: 0 auto;
    }
  `,
  Content: styled.div`
    padding: 0.5rem;
    position: relative;
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  `,
  CloseIcon: styled(FaTimes)`
    position: absolute;
    top: ${({ t }) => (t ? t.toString() + "rem" : "0rem")};
    right: ${({ r }) => (r ? r.toString() + "rem" : "0rem")};
    cursor: ${({ disp }) => (disp ? "pointer" : "none")};
    padding: 0.3rem;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: ${Color.title_color};
    display: ${({ disp }) => (disp ? "inline" : "none")};

    &:hover {
      color: ${Color.container_color};
      background-color: ${Color.title_color};
    }
  `,
  Badge: styled.span`
    padding: 0.1rem 0.5rem;
    background: linear-gradient(
      45deg,
      ${({ cat }) =>
        cat === "true" ? Color.first_color : Color.first_color_alt},
      ${({ cat }) =>
        cat === "true" ? "hsl(112,100%,42%)" : "hsl(42,100%,50%)"}
    );
    color: ${Color.container_color};
    border-radius: ${Radius};
    font-weight: ${({ fw }) => (fw ? fw : "")};
  `,
};
