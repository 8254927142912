import styled from "styled-components";
import { Container, Section } from "../../../style_config/UseStyle";
import {
  Color,
  Font,
  Header_height,
  Points,
} from "../../../style_config/VarStyle";

export default {
  Presentation: styled(Section)`
    padding-top: ${Header_height};
    min-height: 100vh;
    max-height: fit-content;
    height: fit-content;
  `,
  ObjectifContainer: styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
  `,
  Objectif: styled.div`
    background-color: rgba(30, 30, 30, 0.99);
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: relative;

    &::before {
      content: "";
      border-top: 20px solid #fff;
      border-right: 25px solid transparent;
      border-left: 25px solid transparent;
      border-bottom: 25px solid transparent;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  `,
  ObjectifContent: styled.div`
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-template-areas: "opt1 opt2";
    grid-area: opt2;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;

    color: rgba(220, 220, 220, 0.99);
    font-size: ${Font.size.h3_font_size};
    font-weight: ${Font.weight.font_regular};
    text-align: center;

    @media screen and (max-width: ${Points.smallScreen}) {
      font-size: ${Font.size.normal_font_size};
      column-gap: 0.5rem;
      margin: 1rem 3rem 0 0;
    }

    &::after {
      content: "“";
      grid-area: opt1;
      color: ${Color.blue_color};
      font-size: calc(${Font.size.big_font_size} + 6rem);

      @media screen and (max-width: ${Points.mobile}) {
        font-size: calc(${Font.size.big_font_size} + 3rem);
      }
    }
  `,
  Title: styled.h1`
    color: ${({ color_txt }) =>
      color_txt === "gradient"
        ? "transparent"
        : color_txt
        ? color_txt
        : Color.blue_color};
    ${({ color_txt }) =>
      color_txt === "gradient"
        ? `background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;`
        : ""};
    background-image: ${({ cat, color_txt }) =>
      color_txt === "gradient"
        ? cat
          ? "linear-gradient(45deg," + Color.first_color + ",hsl(112,100%,42%))"
          : "linear-gradient(45deg," +
            Color.first_color_alt +
            ", hsl(42,100%,50%))"
        : ""};
    text-transform: ${({ txt_transf }) =>
      txt_transf === 1
        ? "lowercase"
        : txt_transf === 2
        ? "capitalize"
        : "uppercase"};
    line-height: ${({ lh }) => (lh ? "2.5rem" : "")};
    text-align: ${({ pos }) => (pos ? pos : "center")};
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
    font-size: ${({ big }) =>
      big ? Font.size.big_font_size : Font.size.h3_font_size};

    & span {
      color: ${Color.text_color};
      font-size: ${Font.size.small_font_size};
      background-clip: none;
      -webkit-background-clip: none;
      background-image: none;

      &::before {
        content: " - ";
      }
    }
  `,
  DataText: styled.p`
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};

    &:first-letter {
      font-weight: ${Font.weight.font_semi_bold};
      font-size: ${Font.size.h3_font_size};
    }
  `,
  List: styled.ul`
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  `,
  PresentContainer: styled(Container)`
    margin-top: 1rem;
  `,
};
