import styled from "styled-components";
import { Color, Header_height } from "../../../style_config/VarStyle";
import { Section } from "../../../style_config/UseStyle";

export const ScFormationDynamic = styled(Section)`
  padding-top: 0;
  margin-top: ${Header_height};
  min-height: 100vh;
  max-height: fit-content;
  height: fit-content;
  background: ${Color.container_color};
  position: relative;
  z-index: 45;
`;
