import styled from "styled-components";
import { Color, Points, Radius } from "../../style_config/VarStyle";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";

export const SliderArrowLeft = styled(HiArrowSmLeft)`
  left: 0.7rem;
`;
export const SliderArrowRight = styled(HiArrowSmRight)`
  right: 0.7rem;
`;

export const Stylecarousel = styled.div`
  width: 100%;
  height: min-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${Radius};
  overflow: hidden;

  & ${SliderArrowLeft},& ${SliderArrowRight} {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 0.5rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 31;
    cursor: pointer;

    &:hover {
      background-color: ${Color.container_color};
      color: ${Color.first_color};
      transform: translateY(-60%) scale(1.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    & ${SliderArrowLeft},& ${SliderArrowRight} {
      display: none;
    }
  }
`;

export const CarouselWraper = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(-${({ trl = 0 }) => (trl !== 0 ? trl : 0)}%);
  transition: ${({ trs }) => (trs ? "all 1s ease-in-out" : "none")};
`;

export const SliderItem = styled.div`
  flex: 1 0 calc(100% / ${({ show }) => (show ? show + "" : "1")});
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  aspect-ratio: 3/2.5;
  border-radius: ${Radius};
  max-height: 200px;

  & > img {
    aspect-ratio: 3/2.5;
    // max-height: 200px;
    min-height: 100px;
    border-radius: ${Radius};
    object-fit: cover;
    border: ${({ styleImg }) =>
      styleImg ? `2px solid ${Color.first_color}` : "none"};
  }
`;

export const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
`;

export const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgb(230, 230, 230);
  margin-left: 0.5rem;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    background-color: ${Color.first_color};
  }

  @media screen and (max-width: ${Points.mobile}) {
    width: 5px;
    height: 5px;
    margin-left: 0.2rem;
  }
`;
