import { useEffect, useRef, useState } from "react";

export const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

export const useToogle = (initialState) => {
  const [state, setstate] = useState(initialState);

  const Toogle = () => {
    setstate(!state);
  };

  return [state, Toogle];
};

export const useHandlerRadio = (initial) => {
  const [checked, setChecked] = useState(initial);
  const isChecked = (value) => value === checked;
  const oneSelect = ({
    target: {
      dataset: { value },
    },
  }) => {
    setChecked(value);
  };

  return [isChecked, oneSelect, checked];
};

export const useMediaQuerry = (handler, element = window) => {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      element.addEventListener("resize", eventListener);
      element.addEventListener("load", eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener("resize", eventListener);
        element.removeEventListener("load", eventListener);
      };
    },
    [element] // Re-run if element changes
  );
};

export const useOneMount = (callback, bool = true) => {
  const saveBool = useRef();
  const saveCallback = useRef();
  useEffect(() => {
    saveBool.current = bool;
    saveCallback.current = callback;
  }, [bool, callback]);
  useEffect(() => {
    if (saveBool.current) {
      saveCallback.current();
      saveBool.current = false;
    }
  }, []);
};

export const usePagination = (data, recordsPerPage = 3) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastIndex, setLastIndex] = useState(recordsPerPage);
  const [firstIndex, setFirstIndex] = useState(0);
  const nPage = Math.ceil(data.length / recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1);

  useEffect(() => {
    setLastIndex(currentPage * recordsPerPage);
    setFirstIndex(currentPage * recordsPerPage - recordsPerPage);
  }, [currentPage, recordsPerPage]);

  return [
    data.slice(firstIndex, lastIndex),
    {
      index: currentPage,
      setIndex: setCurrentPage,
      numberOfPage: nPage,
      numbers,
    },
  ];
};

export const useSetIntervale = (calback, delay) => {
  useEffect(() => {
    let id = setInterval(calback, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay, calback]);
};

export const useNavColor = (state, setNav) => {
  const setRef = useRef();
  const stateRef = useRef();

  useEffect(() => {
    setRef.current = setNav;
    stateRef.current = state;
  }, [setNav, state]);

  useEffect(() => {
    const seter = () => setRef.current(stateRef.current);
    seter();
  }, []);
};

export function useGetQueryUrl(cb) {
  let obj = {};
  window.location.search
    .slice(1)
    .split("&")
    .forEach((el, i) => {
      let query = el.split("=");
      // query[0] = query[0].replace("?", "");
      obj[query[0]] = query[1];
    });
  if (arguments.length) {
    let res = {};
    Object.entries(obj).map(([key, value], index) => {
      res[key] = cb(value, key, index);
      return null;
    });
    return res;
  }
  return obj;
}
