import React from "react";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { PaginationBlock, PaginationElement } from "./Spagination";

const Pagination = ({ pageInfo }) => {
  const { index, setIndex, numberOfPage, numbers } = pageInfo;

  function nextPage() {
    if (index !== numberOfPage) setIndex(index + 1);
  }
  function toPage(i) {
    setIndex(i);
  }
  function prevPage() {
    if (index !== 1) setIndex(index - 1);
  }
  return (
    <PaginationBlock hidden={numbers.length === 1 || numbers.length === 0}>
      {index !== 1 && (
        <PaginationElement
          radTopLeft="true"
          radBottomLeft="true"
          onClick={(_) => prevPage()}
        >
          <BsFillCaretLeftFill />
        </PaginationElement>
      )}

      {numbers &&
        numbers.map((numberPage) => {
          return (
            <PaginationElement
              radTopLeft="true"
              radTopRight="true"
              radBottomLeft="true"
              radBottomRight
              onClick={(_) => toPage(numberPage)}
              className={numberPage === index ? "active" : ""}
              key={numberPage}
            >
              {numberPage}
            </PaginationElement>
          );
        })}

      {index !== numberOfPage && (
        <PaginationElement
          radTopRight="true"
          radBottomRight="true"
          onClick={(_) => nextPage()}
        >
          <BsFillCaretRightFill />
        </PaginationElement>
      )}
    </PaginationBlock>
  );
};

export default Pagination;
