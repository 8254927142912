import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function AuthLayout() {
  const { credential } = useSelector((state) => state.auth);
  if (credential) {
    return <Navigate to="/dashboard" />;
  }
  return <Outlet />;
}

export default AuthLayout;
