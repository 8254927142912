import React from "react";
import { Link } from "react-router-dom";
import Data from "../../data/DataClient";
import S from "./Sfooter";
import { Hr } from "../../style_config/UseStyle";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/auth.slice";
import { toast } from "react-hot-toast";

const { footer } = Data;

const Footer = () => {
  const { credential } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logout())
      .unwrap()
      .then(({ message }) => {
        toast.success(message);
      });
  };
  return (
    <>
      <S.Footer normal="true">
        <S.Container>
          <S.Box1>
            <S.BoxTitle>{footer.box1.title}</S.BoxTitle>
            <S.BoxText>
              {footer.box1.name}
              <br />
              {footer.box1.adress}
              <br />
              {footer.box1.email}
              <br />
              {footer.box1.tel}
            </S.BoxText>
          </S.Box1>
          <S.Box2>
            <S.BoxTitle>{footer.box2.title}</S.BoxTitle>
            {credential ? (
              <>
                <S.BoxText
                  as={Link}
                  linkcolor="true"
                  to={footer.box2.link[3].path}
                >
                  {footer.box2.link[3].text}
                </S.BoxText>
                <S.BoxText linkcolor="true" onClick={handleLogout}>
                  {footer.box2.link[2].text}
                </S.BoxText>
              </>
            ) : (
              <>
                <S.BoxText
                  as={Link}
                  linkcolor="true"
                  to={footer.box2.link[0].path}
                >
                  {footer.box2.link[0].text}
                </S.BoxText>
                <br />
                <S.BoxText
                  as={Link}
                  linkcolor="true"
                  to={footer.box2.link[1].path}
                >
                  {footer.box2.link[1].text}
                </S.BoxText>
              </>
            )}
          </S.Box2>
          <S.Box3>
            <Hr center="true" white="true" />
            URGPGE-laboratoire-Facultés des Sciences Antananarivo <br />{" "}
            Copyright by Zamanileha E. Fernando
          </S.Box3>
        </S.Container>
      </S.Footer>
    </>
  );
};

export default Footer;
