import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuerry, useNavColor } from "../../../hooks";
import DataClient from "../../../data/DataClient";
import { Color } from "../../../style_config/VarStyle";
import { TableMateriels } from "../../../components/presentation/TableMateriels";
import Carousel from "../../../components/carousel/Carousel";
import S from "./Spresentation";

const Presentation = ({ setnav }) => {
  useNavColor(true, setnav);
  const { presentation } = DataClient;
  const [state, setState] = useState(1);

  useMediaQuerry(() => {
    if (window.innerWidth >= 992) {
      setState(6);
    }
    if (window.innerWidth <= 992) {
      setState(4);
    }
    if (window.innerWidth <= 768) {
      setState(3);
    }
    if (window.innerWidth <= 576) {
      setState(2);
    }
    if (window.innerWidth <= 330) {
      setState(1);
    }
  });

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setState(6);
    }
    if (window.innerWidth <= 992) {
      setState(4);
    }
    if (window.innerWidth <= 768) {
      setState(3);
    }
    if (window.innerWidth <= 576) {
      setState(2);
    }
    if (window.innerWidth <= 330) {
      setState(1);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Présentation - Laboratoire URGPGE</title>
        <meta
          name="keywords"
          content="Présentation,Matériels Et Équipements,Activités,Unité"
        />
        <meta
          name="description"
          content="Fournir aux étudiants des connaissances et des compétences scientifiques, technologiques pour la résolution de problème environnementaux à partir d’une approche pluridisciplinaire.Le Laboratoire de CREG2E accueille des étudiants en stage de fin d’études dans le domaine de CHIMIE et travail aussi avec les industries en résolvant leurs problématiques environnementales. L’expertise comprend des études sur terrain, le diagnostic et le traitement si nécessaire."
        />
      </Helmet>
      <S.Presentation>
        <S.Objectif>
          <S.ObjectifContainer>
            <S.Title>Nos Objectif</S.Title>
            <S.ObjectifContent>
              Fournir aux étudiants des connaissances et des compétences
              scientifiques, technologiques pour la résolution de problème
              environnementaux à partir d’une approche pluridisciplinaire
            </S.ObjectifContent>
          </S.ObjectifContainer>
        </S.Objectif>

        <S.PresentContainer>
          <S.Title
            big={true}
            txt_transf={2}
            mb={0.5}
            color_txt={Color.title_color}
            pos="left"
            lh={true}
          >
            {presentation.presentation.title}
          </S.Title>
          <S.DataText ml={0.5} mb={1}>
            {presentation.presentation.text}
          </S.DataText>

          <S.Title
            big={true}
            txt_transf={2}
            mb={0.5}
            color_txt="rgba(50,50,50,1)"
            pos="left"
            lh={true}
          >
            {presentation.activity.title}
          </S.Title>
          <S.DataText ml={0.5}>{presentation.activity.text}</S.DataText>
          <S.List ml={3} mt={0.5} mb={1}>
            {presentation.activity.list.map(({ data }, i) => (
              <li key={i}>{data}</li>
            ))}
          </S.List>

          <S.Title
            big={true}
            txt_transf={2}
            mb={0.5}
            color_txt={Color.title_color}
            pos="left"
            lh={true}
          >
            {presentation.equipement.title}
          </S.Title>

          <S.Title
            mb={1}
            ml={0.5}
            color_txt="gradient"
            cat={true}
            txt_transf={2}
            pos="left"
          >
            {presentation.equipement.carousel.title}
          </S.Title>
          <Carousel show={state} infiniteLoop={true} autoplay={true} delay={2}>
            {presentation.equipement.carousel.data.map(({ img }, i) => (
              <img src={img} alt="lab equipement" key={i} />
            ))}
          </Carousel>

          <S.Title
            mb={1}
            ml={0.5}
            mt={1}
            color_txt="gradient"
            cat={true}
            txt_transf={2}
            pos="left"
          >
            {presentation.equipement.subTitle1.title}{" "}
            <span>{presentation.equipement.subTitle1.span}</span>
          </S.Title>
          <TableMateriels data={presentation.equipement.table} />

          <S.Title
            mb={1}
            ml={0.5}
            mt={1}
            color_txt="gradient"
            cat={true}
            txt_transf={2}
            pos="left"
          >
            {presentation.equipement.subTitle2.title}{" "}
          </S.Title>
          <S.List ml={3} mt={0.5} mb={1}>
            {presentation.equipement.subTitle2.list.map(({ data }, i) => (
              <li key={i}>{data}</li>
            ))}
          </S.List>
        </S.PresentContainer>
      </S.Presentation>
    </>
  );
};

export default Presentation;
