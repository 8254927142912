import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  Color,
  Font,
  Header_height,
  Points,
  Radius,
  Z_index,
} from "../../style_config/VarStyle";
import { Container } from "../../style_config/UseStyle";

const Style1_Css = css`
  color: ${Color.title_color};
  font-weight: ${Font.weight.font_medium};
`;
const TextLink_Css = css`
  color: ${({ scroll, colorlink }) =>
    colorlink === "true"
      ? Color.text_color
      : scroll === "true"
      ? Color.text_color
      : Color.container_color};
  font-weight: ${Font.weight.font_medium};
  font-size: ${Font.size.normal_font_size};
`;

export const Hero = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: ${({ scroll, colorlink }) =>
    colorlink === "true"
      ? Color.container_color
      : scroll === "true"
      ? Color.container_color
      : "transparent"};
  box-shadow: 0 0 5px
    ${({ scroll, colorlink }) =>
      colorlink === "true"
        ? "rgba(0, 0, 0, 0.2)"
        : scroll === "true"
        ? "rgba(0, 0, 0, 0.2)"
        : "transparent"};
  z-index: ${Z_index.z_fixed};
`;

export const Nav = styled(Container)`
  height: ${Header_height};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavLogo = styled(Link)`
  ${Style1_Css}
  height: calc(${Header_height} - .8rem);
  width: 145px;
`;

export const Nav_Toogle = styled.div`
  ${Style1_Css}
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  @media screen and (max-width: ${Points.mobile}) {
    display: none;
  }
`;

export const NavlinkStyle = styled(NavLink)`
  ${TextLink_Css}
  transition: 0.3s all linear;

  &.active {
    color: ${Color.first_color_alt};
  }

  &:hover {
    color: ${Color.first_color};
  }
`;

export const DropdownStyle = styled.div`
  background-color: ${Color.container_color};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: ${Radius};
  position: absolute;
  top: 101%;
  display: none;
  flex-direction: column;
  overflow: hidden;
`;

export const DropdownName = styled.div`
  ${TextLink_Css}
  text-align: left;

  svg {
    font-size: 0.6rem;
    margin-left: 5px;
  }

  &:hover {
    color: ${Color.first_color};
    svg {
      transform: rotate(-180deg);
    }
  }
`;

export const NavItem = styled.div`
  padding: 10px;
  z-index: 10;
  transition: 0.3s all linear;

  &:hover {
    ${NavlinkStyle} {
      color: ${Color.first_color};
    }

    ${DropdownStyle} {
      display: flex;
    }

    ${DropdownName} {
      color: ${Color.first_color};

      svg {
        transform: rotate(-180deg);
      }
    }
  }
`;

export const Dropdownlink = styled(NavLink)`
  ${TextLink_Css}
  color: ${Color.text_color};
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }

  &.active {
    color: ${Color.first_color_alt};
  }
`;

export const StyledBottom = styled.div`
  position: absolute;
  left: ${({ left }) => (left === -10 ? "-10px" : left + "px")};
  width: ${({ width }) => (width ? width + "px" : "0px")};
  height: 100%;
  top: 0;
  border-bottom: 2px solid ${Color.first_color};
`;

export const MobileIcon = styled.div`
  display: none;
  font-size: ${Font.size.h3_font_size};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 1s linear;

  &::before {
    content: "";
    position: absolute;
    height: ${({ annim }) => (annim ? "100%" : "0%")};
    width: ${({ annim }) => (annim ? "100%" : "0%")};
    background-color: rgba(0, 0, 0, 0.11);
  }

  svg {
    color: #699490;
    cursor: pointer;
  }

  @media screen and (max-width: ${Points.mobile}) {
    display: flex;
  }
`;
