import React from "react";
import { Link } from "react-router-dom";
import { Color, Font } from "../../style_config/VarStyle";
import { Button } from "../../style_config/UseStyle";
import { SCNContainer, SCNErrorText, SCNotFound, SCNText } from "./SCnotfound";

export default function NotFound() {
  return (
    <SCNotFound>
      <SCNContainer>
        <SCNErrorText fs={Font.weight.font_semi_bold}>404</SCNErrorText>
        <SCNText
          mb={1}
          as={"h3"}
          cas={1}
          talign="center"
          fs={Font.size.big_font_size}
          fw={Font.weight.font_semi_bold}
          tcolor={Color.title_color}
        >
          Oops! page non trouvée
        </SCNText>
        <SCNText as="p" mb={2} talign="center">
          La page que vous essayez de trouver n'existe pas,
          <br />
          Cliquer sur le bouton pour revenir à l'acceuil.
        </SCNText>
        <Button to="/" as={Link} grad="true" center="true">
          Acceuil
        </Button>
      </SCNContainer>
    </SCNotFound>
  );
}
