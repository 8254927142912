import logo from "./logo.svg";

// import slides image
import slide1 from "./homeSlide/slide-1.jpg";
import slide2 from "./homeSlide/slide-2.jpg";
import slide3 from "./homeSlide/slide-3.jpg";
import slide4 from "./homeSlide/slide-4.jpg";
import slide5 from "./homeSlide/slide-5.jpg";
import slide6 from "./homeSlide/slide-6.jpg";
import slide7 from "./homeSlide/slide-7.jpg";
import slide8 from "./homeSlide/slide-8.jpg";

// Materiel Carousel
import mc1 from "./materials/Image1.jpg";
import mc2 from "./materials/Image2.jpg";
import mc3 from "./materials/Image3.jpg";
import mc4 from "./materials/Image4.jpg";
import mc5 from "./materials/Image5.jpg";
import mc6 from "./materials/Image6.jpg";
import mc7 from "./materials/Image7.jpg";
import mc8 from "./materials/Image8.jpg";
import mc9 from "./materials/Image9.jpg";
import mc10 from "./materials/Image10.jpg";
import mc11 from "./materials/Image11.jpg";
import mc12 from "./materials/Image12.jpg";
import mc13 from "./materials/Image13.jpg";
import mc14 from "./materials/Image14.jpg";
import mc15 from "./materials/Image15.jpg";
import mc16 from "./materials/Image16.jpg";
import mc17 from "./materials/Image17.jpg";
import mc18 from "./materials/Image18.jpg";
import mc19 from "./materials/Image19.jpg";
import mc20 from "./materials/Image20.jpg";
import mc21 from "./materials/Image21.jpg";
import mc22 from "./materials/Image22.jpg";
import mc23 from "./materials/Image23.jpg";

// icon for list
import list from "./right.svg";

import randomProfile from "./random_profile.svg";

export const Logo = logo;

export const SlideImg = [
  {
    img: slide1,
  },
  {
    img: slide2,
  },
  {
    img: slide3,
  },
  {
    img: slide4,
  },
  {
    img: slide5,
  },
  {
    img: slide6,
  },
  {
    img: slide7,
  },
  {
    img: slide8,
  },
];

export const MaterielCarousel = [
  {
    img: mc1,
  },
  {
    img: mc2,
  },
  {
    img: mc3,
  },
  {
    img: mc4,
  },
  {
    img: mc5,
  },
  {
    img: mc6,
  },
  {
    img: mc7,
  },
  {
    img: mc8,
  },
  {
    img: mc9,
  },
  {
    img: mc10,
  },
  {
    img: mc11,
  },
  {
    img: mc12,
  },
  {
    img: mc13,
  },
  {
    img: mc14,
  },
  {
    img: mc15,
  },
  {
    img: mc16,
  },
  {
    img: mc17,
  },
  {
    img: mc18,
  },
  {
    img: mc19,
  },
  {
    img: mc20,
  },
  {
    img: mc21,
  },
  {
    img: mc22,
  },
  {
    img: mc23,
  },
];

export const IcoList = list;

export const RandomProfile = randomProfile;
