import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import { useToogle } from "../hooks";
import Sidebar from "./navbar/Sidebar";

const Layout = ({ nav }) => {
  const [show, setShow] = useToogle(false);
  const [annim, setAnnim] = useToogle(false);
  return (
    <>
      <Navbar nav={nav} setAnnim={setAnnim} annim={annim} setShow={setShow} />
      <Sidebar show={show} setShow={setShow} setAnnim={setAnnim} />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
