import React from "react";
import { Color } from "../../style_config/VarStyle";
import {
  STable,
  STableContainer,
  STBody,
  STBodyTR,
  STD,
  STH,
  STHead,
  STHeadTR,
} from "../../style_config/UseStyle";

export const TableMateriels = ({ data }) => {
  const data1 = data.analyses_physicochimiques;
  const data2 = data.analyses_microbiologiques;

  const keysSubtitle = Object.keys(data);
  const keysTitle = Object.keys(data1[0]);

  return (
    <STableContainer>
      <STable w={100}>
        <STHead>
          <STHeadTR>
            {keysTitle.map((obj, i) => (
              <STH key={i}>{obj.replace("_", " ")}</STH>
            ))}
          </STHeadTR>
        </STHead>
        <STBody>
          <STBodyTR>
            <STD
              colSpan={3}
              txt_trsf={3}
              bold={true}
              text_color={Color.blue_color}
            >
              {keysSubtitle[0].replace("_", " ")}
            </STD>
          </STBodyTR>

          {data1.map((obj, i) => (
            <STBodyTR key={i}>
              {keysTitle.map((key, i) => {
                const value = obj[key];
                if (i === 1) {
                  return (
                    <STD key={i} spanmb={0.3} spanmt={0.3}>
                      {value.map((item, i) => (
                        <span key={i}>{item}</span>
                      ))}
                    </STD>
                  );
                }

                if (i === 2) {
                  return (
                    <STD key={i} spanmb={0.3} spanmt={0.3} spanbold={true}>
                      {value.map((item, i) => (
                        <span key={i}>{item}</span>
                      ))}
                    </STD>
                  );
                }

                return (
                  <STD bold={true} key={i}>
                    {value}
                  </STD>
                );
              })}
            </STBodyTR>
          ))}

          <STBodyTR>
            <STD
              colSpan={3}
              txt_trsf={3}
              bold={true}
              text_color={Color.blue_color}
            >
              {keysSubtitle[1].replace("_", " ")}
            </STD>
          </STBodyTR>

          {data2.map((obj, i) => (
            <STBodyTR key={i}>
              {keysTitle.map((key, i) => {
                const value = obj[key];
                if (i === 1) {
                  return (
                    <STD key={i} spanmb={0.3} spanmt={0.3}>
                      {value.map((item, i) => (
                        <span key={i}>{item}</span>
                      ))}
                    </STD>
                  );
                }

                if (i === 2) {
                  return (
                    <STD key={i} spanmb={0.3} spanmt={0.3} spanbold={true}>
                      {value.map((item, i) => (
                        <span key={i}>{item}</span>
                      ))}
                    </STD>
                  );
                }

                return (
                  <STD bold={true} key={i}>
                    {value}
                  </STD>
                );
              })}
            </STBodyTR>
          ))}
        </STBody>
      </STable>
    </STableContainer>
  );
};
