import React from "react";
import { Helmet } from "react-helmet";
import Pagination from "../../../components/pagination/pagination";
import FilterYears from "../../../components/helpers/FilterYears";
import DataClient from "../../../data/DataClient";
import { useHandlerRadio, usePagination, useNavColor } from "../../../hooks";
import { Color, Font } from "../../../style_config/VarStyle";
import { Hr } from "../../../style_config/UseStyle";
import {
  GridLayout,
  GridLeft,
  GridRight,
  SCTCloseIcon,
  SCTData,
  SCTheses,
  SCThesesContainer,
  SCTText,
  SCTTitle,
} from "./Stheses";

export default function Theses({ setnav }) {
  useNavColor(true, setnav);
  const { thesesFixture } = DataClient;
  const [isChecked, oneSelect, checked] = useHandlerRadio("0");
  const [data, page] = usePagination(
    thesesFixture
      .filter((obj) =>
        checked === "0"
          ? obj
          : checked === obj.date.getFullYear().toString()
          ? obj
          : ""
      )
      .sort((a, b) => b.date.getTime() - a.date.getTime()),
    2
  );
  return (
    <>
      <Helmet>
        <title>Thèses - Laboratoire URGPGE</title>
        <meta name="keywords" content="Recherche,thèses publiées" />
        <meta name="description" content="La liste des thèses publiées." />
      </Helmet>
      <SCTheses>
        <SCThesesContainer>
          <SCTTitle shadow_box_color={true} pad={1} bgc={Color.container_color}>
            La liste des thèses publiées
          </SCTTitle>
          <GridLayout>
            <GridLeft>
              {data.length !== 0 ? (
                data.map((obj, i) => (
                  <SCTData ml={0.4} cat={true} key={i} href={obj.doc}>
                    <SCTTitle
                      txt_color="gradient"
                      cat={true}
                      lh={true}
                      mb={0.5}
                      size={Font.size.h2_font_size}
                      dangerouslySetInnerHTML={{ __html: obj.title }}
                    />

                    <SCTText fw={Font.weight.font_semi_bold}>
                      Par : {obj.auteur}
                    </SCTText>
                    <SCTText>
                      Directeur de thèse : {obj.directeur}
                      <br />
                      Présentation :
                      {" " +
                        obj.date.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </SCTText>
                  </SCTData>
                ))
              ) : (
                <SCTData as="div">Aucun résultat trouvée</SCTData>
              )}
            </GridLeft>
            <GridRight>
              <SCTCloseIcon
                data-value="0"
                disp={checked !== "0" ? "true" : "false"}
                opacity={checked !== "0" ? 1 : 0}
                onClick={oneSelect}
              />
              <SCTTitle
                txt_color={Color.title_color}
                lh={true}
                mb={0.5}
                size={Font.size.h3_font_size}
                txt_align="center"
              >
                Filtre
              </SCTTitle>
              <Hr mb={1} center={true} />
              <FilterYears
                data={thesesFixture}
                isChecked={isChecked}
                oneSelect={oneSelect}
              />
            </GridRight>
          </GridLayout>
          <Pagination pageInfo={page} />
        </SCThesesContainer>
      </SCTheses>
    </>
  );
}
