import React from "react";
import { Helmet } from "react-helmet";
import Pagination from "../../../components/pagination/pagination";
import BoxAnnuaire from "../../../components/contact/BoxAnnuaire";
import DataClient from "../../../data/DataClient";
import { usePagination, useNavColor } from "../../../hooks";
import { Color } from "../../../style_config/VarStyle";
import { AnnuaireContainer, AnnuaireStyle, AnnuaireTitle } from "./Sannuaire";

const Annuaire = ({ setnav }) => {
  useNavColor(true, setnav);
  const { annuaireFixture } = DataClient;
  const [data, page] = usePagination(annuaireFixture, 5);

  return (
    <>
      <Helmet>
        <title>Annuaire - Laboratoire URGPGE</title>
        <meta
          name="keywords"
          content="Annuaire,Chimie, génie des procédés,bioprocédés,eau,environnement"
        />
        <meta name="description" content="La liste des thèses publiées." />
      </Helmet>
      <AnnuaireStyle>
        <AnnuaireContainer>
          <AnnuaireTitle
            pad={1}
            shadow_box_color={true}
            bgc={Color.container_color}
          >
            Annuaire
          </AnnuaireTitle>

          {data.map((item, i) => (
            <BoxAnnuaire data={item} key={i} />
          ))}
          <Pagination pageInfo={page} />
        </AnnuaireContainer>
      </AnnuaireStyle>
    </>
  );
};

export default Annuaire;
