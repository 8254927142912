import React, { useState } from "react";
import { Link } from "react-router-dom";
import S from "./Sauth";
import { Logo } from "../../../data/assets";
import SignIn from "../../../components/auth/SignIn";
import SignUp from "../../../components/auth/SignUp";
import { useGetQueryUrl } from "../../../hooks";

export default function Auth() {
  const { l } = useGetQueryUrl((value, key) =>
    key === "l" ? !(value === "true") : value
  );
  const [signIn, setSignIn] = useState(l);
  return (
    <S.Auth>
      <S.BlockForm flex={!signIn}>
        {signIn ? (
          <SignUp setSignIn={setSignIn} />
        ) : (
          <SignIn setSignIn={setSignIn} />
        )}
      </S.BlockForm>
      <S.BlockStyle>
        <S.Image>
          <img src={Logo} alt="Logo" />
        </S.Image>
        <S.Button to="/" as={Link}>
          Acceuil
        </S.Button>
      </S.BlockStyle>
    </S.Auth>
  );
}
