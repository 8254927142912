import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";
import { Color, Font, Points } from "../../style_config/VarStyle";
import { Container } from "../../style_config/UseStyle";

export const HeroSection = styled.section`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.4) 9%, transparent),
    linear-gradient(115deg, rgba(0, 0, 0, 0.5) 52%, transparent);
  background-blend-mode: overlay;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -175px;
    left: 50%;
    height: 200px;
    width: 250%;
    background: ${Color.bg_color};
    transform: translateX(-50%) rotate(3deg);
    z-index: 0;
  }

  @media screen and (min-width: ${Points.desktop}) {
    &::before {
      bottom: -24.8%;
    }
  }

  @media screen and (max-width: ${Points.desktop}) {
    &::before {
      bottom: -165px;
    }
  }

  @media screen and (max-width: ${Points.tablet}) {
    &::before {
      bottom: -170px;
    }
  }
  @media screen and (max-width: ${Points.mobile}) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4) 9%, transparent);
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-height: 700px) {
    height: 700px;
  }
`;

export const  HeroSlides = styled.div`
  display: flex;
  position: absolute;
  transition: ${({ transition }) => (transition ? "none" : ".3s")};
  transform: translateX(calc(-100vw * ${({ index }) => index}));
  z-index: -5;
`;
export const HeroSlide = styled.div`
  height: 100vh;
  @media screen and (min-height: 700px) {
    height: 700px;
  }

  img {
    object-fit: cover;
    min-width: 100vw;
    max-width: 100vw;
    height: 100vh;
    filter: grayscale();
    @media screen and (min-height: 700px) {
      height: 700px;
    }
  }
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 2rem;

  @media screen and (max-width: ${Points.mobile}) {
    align-items: center;
  }
`;

export const HeroH1 = styled.h1`
  color: ${Color.first_color};
  font-size: ${Font.size.big_font_size};
  margin-right: 35%;
  line-height: 3rem;
  background: linear-gradient(45deg, ${Color.first_color}, hsl(112, 100%, 42%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: ${Points.mobile}) {
    margin: 0 2rem;
    text-align: center;
  }
  @media screen and (max-width: ${Points.smallScreen}) {
    margin: 0;
    font-size: ${Font.size.h1_font_size};
    line-height: normal;
  }
  @media screen and (max-width: ${Points.extraSmallScreen}) {
    margin: 0;
    font-size: ${Font.size.h2_font_size};
  }
`;

export const HeroP = styled.p`
  color: ${Color.container_color};
  font-weight: ${Font.weight.font_medium};
  line-height: 25px;
  margin-right: 35%;

  @media screen and (max-width: ${Points.mobile}) {
    margin: 0 2rem;
    font-size: ${Font.size.normal_font_size};
    text-align: center;
  }
  @media screen and (max-width: ${Points.extraSmallScreen}) {
    margin: 0;
    font-size: ${Font.size.small_font_size};
    line-height: 17px;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  font-size: ${Font.size.normal_font_size};
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  font-size: ${Font.size.normal_font_size};
`;
