import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-hot-toast";
import S from "./Styles";
import Spiner from "../../components/spiner";
import { useEventListener } from "../../hooks";
import DataClient from "../../data/DataClient";
import {
  Button,
  Input,
  Label,
  Option,
  Select,
} from "../../style_config/UseStyle";
import { Color, Font } from "../../style_config/VarStyle";
import { register } from "../../store/auth.slice";

const {
  auth: { signUp: clientData },
} = DataClient;

const initialState = (dataArray) => {
  let res = {};
  dataArray.map((option) => {
    if (option.required) {
      res[option.name] = undefined;
    }
    return null;
  });
  return res;
};

const selectWatchCanSubmitData = (dataArray, state) => {
  const res = dataArray.reduce((acc, cur) => {
    return cur.required ? [...acc, state[cur.name]] : [...acc];
  }, []);
  return res.every(Boolean);
};

export default function SignUp({ setSignIn }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [showPwd, setShowPwd] = useState({});
  const [state, setState] = useState(initialState(clientData.form));
  const canSubmit =
    !selectWatchCanSubmitData(clientData.form, state) && !isLoading;

  const inputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.password === state.passwordConfirm) {
      if (
        state.tel &&
        !/^(\+\d{3})\s?(\d{2})\s?(\d{2})\s?(\d{3})\s?(\d{2})$/.test(state.tel)
      ) {
        toast.error("Le numéro de téléphone n'est pas au bon format");
        return;
      }
      let sendData = {};
      Object.entries(state).map(([key, value]) => {
        switch (key) {
          case "tel":
            sendData[key] = value.replace(
              /^(\+\d{3})\s?(\d{2})\s?(\d{2})\s?(\d{3})\s?(\d{2})$/,
              "$1$2$3$4$5"
            );
            return null;
          case "speciality":
            sendData[key] = state[key].reduce((acc, cur) => {
              return [...acc, cur.toLowerCase()];
            }, []);
            return null;
          case "passwordConfirm":
            return null;

          default:
            sendData[key] = value.toLowerCase();
            return null;
        }
      });
      dispatch(register(sendData))
        .unwrap()
        .then(({ message }) => {
          toast.success(message);
          navigate("/dashboard");
          return;
        })
        .catch(({ message }) => {
          toast.error(message);
          return;
        });
    } else {
      toast.error("Le confirmation ne correspond pas au mot de passe!");
      return;
    }
  };

  useEventListener(
    "keydown",
    (e) => {
      if (e.code === "Enter" && !canSubmit) {
        handleSubmit(e);
      }
    },
    document
  );
  return (
    <S.Data flex={true}>
      <S.Title
        grad="true"
        bg={`linear-gradient(45deg,${Color.first_color},hsl(112, 100%, 42%))`}
        talign="center"
        fw={Font.weight.font_semi_bold}
      >
        {clientData.title}
      </S.Title>
      {clientData.form.map((item, i) => {
        switch (item.type) {
          case "password":
            return (
              <div key={i}>
                <Label mt={1} mb={0.5} fw={1} htmlFor={item.name}>
                  {item.label} {item.required && <span>*</span>}
                </Label>
                <S.Infield key={i}>
                  <Input
                    type={showPwd[item.name] ? "text" : "password"}
                    placeholder={item.placeholder}
                    name={item.name}
                    pad="0.5rem 1rem"
                    w="100%"
                    onChange={inputChange}
                    value={state[item.name] ? state[item.name] : ""}
                  />
                  {showPwd[item.name] ? (
                    <AiFillEye
                      onClick={(e) =>
                        setShowPwd({
                          ...showPwd,
                          [item.name]: !showPwd[item.name],
                        })
                      }
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={(e) =>
                        setShowPwd({
                          ...showPwd,
                          [item.name]: !showPwd[item.name],
                        })
                      }
                    />
                  )}
                </S.Infield>
              </div>
            );

          case "radio":
            return (
              <div key={i}>
                <Label as="legend" fw={1}>
                  {item.label} {item.required && <span>*</span>}
                </Label>
                <S.FlexDisplay>
                  {item?.options.map((option, i2) => (
                    <S.FlexDisplay key={i2} cursorPointer={true}>
                      <Input
                        type={item.type}
                        name={item.name}
                        value={option.value}
                        checked={
                          state[item.name] && state[item.name] === option.value
                        }
                        onChange={inputChange}
                      />
                      <div
                        onClick={(e) =>
                          setState({ ...state, [item.name]: e.target.value })
                        }
                      />
                      <Label
                        ml={0.3}
                        fw={1}
                        onClick={(e) =>
                          setState({ ...state, [item.name]: option.value })
                        }
                      >
                        {option.label}
                      </Label>
                    </S.FlexDisplay>
                  ))}
                </S.FlexDisplay>
              </div>
            );

          case "select":
            const handleSelect = (value) =>
              setState((p) =>
                p[item.name]
                  ? p[item.name].includes(value)
                    ? {
                        ...p,
                        [item.name]: p[item.name].filter(
                          (ele) => value !== ele
                        ),
                      }
                    : { ...p, [item.name]: [...p[item.name], value] }
                  : value !== "Chimie"
                  ? { ...p, [item.name]: ["Chimie", value] }
                  : { ...p, [item.name]: [value] }
              );
            return (
              <div key={i}>
                <Label mt={1.5} mb={0.5} fw={1} htmlFor={item.name}>
                  {item.label} {item.required && <span>*</span>}
                </Label>
                <S.Select h="60px" pad="0.5rem 1rem" w="100%" multiple>
                  {item?.options.map((option, i2) => (
                    <S.Option
                      key={i2}
                      onClick={(e) => handleSelect(option.value)}
                      onChange={(e) => handleSelect(option.value)}
                      // value={option.value}
                      // defaultValue={option.value}
                      checked={
                        state[item.name] &&
                        state[item.name].includes(option.value)
                      }
                    >
                      {option.label}
                    </S.Option>
                  ))}
                </S.Select>
              </div>
            );

          default:
            return (
              <div key={i}>
                <Label mt={1} mb={0.5} fw={1} htmlFor={item.name}>
                  {item.label} {item.required && <span>*</span>}
                </Label>
                <Input
                  type={item.type}
                  placeholder={item.placeholder}
                  name={item.name}
                  pad="0.5rem 1rem"
                  w="100%"
                  onChange={inputChange}
                  value={state[item.name] ? state[item.name] : ""}
                />
              </div>
            );
        }
      })}

      {isLoading ? (
        <Spiner style={{ margin: "1.02rem auto 0rem auto" }} size={"2.49rem"} />
      ) : (
        <Button
          type="submit"
          mt={1}
          center="true"
          grad="true"
          onClick={handleSubmit}
          disabled={canSubmit}
        >
          {clientData.submit}
        </Button>
      )}
      <S.Text>
        {clientData.link.text}{" "}
        <S.Link
          to={clientData.link.path}
          grad="true"
          bg={`linear-gradient(45deg,${Color.first_color},hsl(112, 100%, 42%))`}
          onClick={(e) => setSignIn(false)}
        >
          {clientData.link.a}
        </S.Link>
      </S.Text>
    </S.Data>
  );
}
