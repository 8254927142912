import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Section } from "../../../style_config/UseStyle";
import {
  Color,
  Font,
  Header_height,
  Points,
  Radius,
} from "../../../style_config/VarStyle";

export const SCSearch = styled(Section)`
  margin-top: ${Header_height};
  height: fit-content;
  min-height: 100vh;
  background: ${Color.bg_color};
  padding-top: 1rem;
`;

export const SCSTitle = styled.h1`
  font-size: ${({ size }) => (size ? size : Font.size.big_font_size)};
  color: ${({ txt_color }) =>
    txt_color === "gradient"
      ? "transparent"
      : txt_color
      ? txt_color
      : Color.title_color};

  ${({ txt_color }) =>
    txt_color === "gradient"
      ? `background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;`
      : ""}
  background-image: ${({ cat, txt_color }) =>
    txt_color === "gradient"
      ? cat
        ? "linear-gradient(45deg," + Color.first_color + ",hsl(112,100%,42%))"
        : "linear-gradient(45deg," +
          Color.first_color_alt +
          ", hsl(42,100%,50%))"
      : ""};
  text-transform: ${({ txt_case }) =>
    txt_case === 1
      ? "lowercase"
      : txt_case === 2
      ? "capitalize"
      : txt_case === 3
      ? "uppercase"
      : "none"};
  line-height: ${({ lh }) => (lh ? "1.5rem" : "2.5rem")};
  padding: ${({ pad }) => (pad ? pad + "rem" : "none")};
  text-align: ${({ txt_align }) => (txt_align ? txt_align : "")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  background-color: ${({ bgc }) => (bgc ? bgc : "none")};
  box-shadow: 0 2px 3px
    ${({ shadow_box_color }) =>
      shadow_box_color ? "rgba(0, 0, 0, 0.1)" : "transparent"};
`;

export const SCGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "opt0 opt1";
  margin: 1rem 0;
  grid-gap: 1rem;

  @media screen and (max-width: ${Points.mobile}) {
    grid-template-columns: 1fr;
    grid-template-areas: "opt1" "opt0";
  }
`;

export const SCGridLeft = styled.div`
  grid-area: opt0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

export const SCGridRight = styled.div`
  grid-area: opt1;
  padding: 1rem;
  background-color: ${Color.container_color};
  height: min-content;
  width: min-content;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: ${Radius};
  height: fit-content;
  max-width: 300px;
  width: fit-content;
  min-width: 200px;

  @media screen and (max-width: ${Points.mobile}) {
    margin: 0 auto;
  }
`;

export const SCSDImage = styled.div`
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translateY(-50%);
  height: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 8px;
  background: linear-gradient(to right, #d3cce3 75%, #e9e4f0);

  & img {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    padding: 5px;
    background: linear-gradient(
      to right,
      ${({ cat }) => (cat ? Color.first_color : Color.first_color_alt)},
      ${({ cat }) => (cat ? "hsl(112,100%,42%)" : "hsl(42,100%,50%)")}
    );
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SCSData = styled.div`
  padding: 1rem;
  padding-left: 80px;
  background-color: ${Color.container_color};
  position: relative;
  margin-left: ${({ none }) => (none ? "0px" : "50px")};
  border-radius: ${Radius} 0 0 ${Radius};
  margin-right: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

  &::after {
    content: "";
    background: linear-gradient(
      45deg,
      ${({ cat }) => (cat ? Color.first_color : Color.first_color_alt)},
      ${({ cat }) => (cat ? "hsl(112,100%,42%)" : "hsl(42,100%,50%)")}
    );
    position: absolute;
    right: -5px;
    top: 0;
    width: 5px;
    height: 100%;
    border-top-right-radius: ${Radius};
    border-bottom-right-radius: ${Radius};
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.2s linear;
  }
  &:hover {
    background-color: rgb(248, 248, 248);

    &::after {
      background: ${Color.title_color};
    }
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    margin-left: 0px;
    margin-top: 60px;
    padding-top: 70px;
    padding-left: 1rem;
  }
`;

export const SCSText = styled.p`
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  font-weight: ${({ fw }) => (fw ? fw : "")};
  font-size: ${({ fs }) => (fs ? fs : "")};
  color: ${({ txt_color }) => (txt_color ? txt_color : Color.title_color)};
`;

export const SCSBadge = styled.span`
  padding: 0.1rem 0.5rem;
  background: linear-gradient(
    45deg,
    ${({ cat }) => (cat ? Color.first_color : Color.first_color_alt)},
    ${({ cat }) => (cat ? "hsl(112,100%,42%)" : "hsl(42,100%,50%)")}
  );
  color: ${Color.container_color};
  border-radius: ${Radius};
  font-weight: ${({ fw }) => (fw ? fw : "")};
`;

export const SCSGContent = styled.div`
  padding: 0.5rem;
  position: relative;
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
`;

export const SCSCloseIcon = styled(FaTimes)`
  position: absolute;
  top: ${({ t }) => (t ? t.toString() + "rem" : "0rem")};
  right: ${({ r }) => (r ? r.toString() + "rem" : "0rem")};
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: ${Color.title_color};
  display: ${({ disp }) => (disp === "true" ? "inline" : "none")};

  &:hover {
    color: ${Color.container_color};
    background-color: ${Color.title_color};
  }
`;
