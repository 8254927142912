import React from "react";
import { Helmet } from "react-helmet";
import Pagination from "../../../components/pagination/pagination";
import FilterCat from "../../../components/helpers/FilterCat";
import FilterYears from "../../../components/helpers/FilterYears";
import DataClient from "../../../data/DataClient";
import { useHandlerRadio, usePagination, useNavColor } from "../../../hooks";
import { Color, Font } from "../../../style_config/VarStyle";
import { Container, Hr } from "../../../style_config/UseStyle";
import {
  SCGridLayout,
  SCGridLeft,
  SCGridRight,
  SCSBadge,
  SCSCloseIcon,
  SCSData,
  SCSDImage,
  SCSearch,
  SCSGContent,
  SCSText,
  SCSTitle,
} from "./Ssearch";

export default function Search({ setnav }) {
  useNavColor(true, setnav);
  const { searchFixture } = DataClient;
  const [isChecked, oneSelect, checked] = useHandlerRadio("0");
  const [isCheckedYears, oneSelectYears, checkedYears] = useHandlerRadio("0");

  const filtre = (item) => {
    let temp = item;
    if (checked !== "0" && checkedYears !== "0") {
      temp =
        checked === temp.categories &&
        checkedYears === temp.date.map((e) => e.getFullYear()).join(" - ")
          ? temp
          : null;
    } else if (checked !== "0" && checkedYears === "0") {
      temp = checked === temp.categories ? temp : null;
    } else if (checked === "0" && checkedYears !== "0") {
      temp =
        checkedYears === temp.date.map((e) => e.getFullYear()).join(" - ")
          ? temp
          : null;
    }
    return temp;
  };

  const [data, page] = usePagination(
    searchFixture
      .filter(filtre)
      .sort(
        ({ date: a }, { date: b }) => b[0].getFullYear() - a[0].getFullYear()
      ),
    5
  );

  return (
    <>
      <Helmet>
        <title>Thèses en cours - Laboratoire URGPGE</title>
        <meta name="keywords" content="Recherche,thèses en cours" />
        <meta
          name="description"
          content="La liste des thèses en cours de recherche."
        />
      </Helmet>
      <SCSearch>
        <Container>
          <SCSTitle shadow_box_color={true} pad={1} bgc={Color.container_color}>
            Liste des thèses en cours
          </SCSTitle>
          <SCGridLayout>
            <SCGridLeft>
              {data.length !== 0 ? (
                data.map((obj, index) => {
                  const cat = obj.categories === "Eau et environnement";
                  return (
                    <SCSData cat={cat} key={index}>
                      <SCSDImage cat={cat}>
                        <img src={obj.image} alt="profile" />
                      </SCSDImage>
                      <SCSText
                        fs={Font.size.h2_font_size}
                        fw={Font.weight.font_semi_bold}
                      >
                        {obj.auteur}
                      </SCSText>
                      <SCSBadge cat={cat} fw={Font.weight.font_semi_bold}>
                        {obj.categories}
                      </SCSBadge>
                      <SCSText
                        dangerouslySetInnerHTML={{ __html: obj.description }}
                      />
                      <SCSText txt_color={Color.text_color_light}>
                        {obj.date.map((e) => e.getFullYear()).join(" - ")}
                      </SCSText>
                    </SCSData>
                  );
                })
              ) : (
                <SCSData none>Aucun résultat trouvée</SCSData>
              )}
            </SCGridLeft>
            <SCGridRight>
              {/* First Content */}
              <SCSGContent>
                <SCSCloseIcon
                  t={0.7}
                  r={0.5}
                  data-value="0"
                  disp={checked !== "0" ? "true" : "false"}
                  opacity={checked !== "0" ? 1 : 0}
                  onClick={oneSelect}
                />
                <SCSTitle lh={true} mb={0.5} size={Font.size.normal_font_size}>
                  Filtré par catégories
                </SCSTitle>
                <Hr mb={1} center={true} w={100} />
                <FilterCat
                  data={searchFixture}
                  isChecked={isChecked}
                  oneSelect={oneSelect}
                />
              </SCSGContent>

              {/* Second Content */}
              <SCSGContent>
                <SCSCloseIcon
                  data-value="0"
                  t={0.7}
                  r={0.5}
                  disp={checkedYears !== "0" ? "true" : "false"}
                  opacity={checkedYears !== "0" ? 1 : 0}
                  onClick={oneSelectYears}
                />
                <SCSTitle lh={true} mb={0.5} size={Font.size.normal_font_size}>
                  Filtré par années
                </SCSTitle>
                <Hr mb={1} center={true} w={100} />
                <FilterYears
                  data={searchFixture}
                  isChecked={isCheckedYears}
                  oneSelect={oneSelectYears}
                />
              </SCSGContent>
            </SCGridRight>
          </SCGridLayout>
          <Pagination pageInfo={page} />
        </Container>
      </SCSearch>
    </>
  );
}
