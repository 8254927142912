import styled from "styled-components";
import { Color, Font, Points } from "../../style_config/VarStyle";
import { Container } from "../../style_config/UseStyle";

export default {
  Footer: styled.footer`
    padding: 3rem 1rem 1rem 1rem;
    position: relative;
    background-color: #161616;
  `,
  Container: styled(Container)`
      color: ${Color.container_color};
      display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "section1 section2" "section3 section3";
    gap: 1rem;
    justify-content: center;

    @media screen and (max-width: ${Points.smallScreen}) {
      grid-template-columns: minmax(min-content, max-content);
      grid-template-areas: "section1" "section2" "section3";
    }
  `,
  Box1: styled.div`
    grid-area: section1;
  `,
  Box2: styled.div`
    grid-area: section2;
  `,
  Box3: styled.div`
    grid-area: section3;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: calc(${Font.size.smaller_font_size} - 0.1rem);
    font-weight: ${Font.weight.font_regular};
    line-height: 20px;
  `,
  BoxTitle: styled.h2`
    color: ${Color.container_color};
    font-size: ${Font.size.h2_font_size};
    font-weight: ${Font.weight.font_semi_bold};
    text-transform: uppercase;
    margin-bottom: 1rem;
  `,
  BoxText: styled.p`
    color: ${({ linkcolor }) =>
      linkcolor ? Color.blue_color : Color.input_color};
    line-height: 30px;
    cursor: ${({ linkcolor }) => (linkcolor ? "pointer" : "text")};

    ${({ linkcolor }) =>
      linkcolor
        ? `&:hover {
          color: ${Color.container_color};
        }`
        : ""}
  `,
};
