import { Link } from "react-scroll";
import styled, { css } from "styled-components";
import { Color, Font, Margin, Points, Radius, Z_index } from "./VarStyle";

// For  the section class
export const Section = styled.section`
  padding: 2rem 0 2rem;
`;

export const SectionTitleCss = css`
  font-size: ${Font.size.h1_font_size};
  text-align: center;
`;

export const SectionSubtitleCss = css`
  font-size: ${Font.size.small_font_size};
  display: block;
  margin-bottom: ${Margin.mb_3};
  text-align: center;
`;

// For Layout classe
export const Container = styled.div`
  max-width: 75%;
  margin: 0 auto;
  padding: 0 1rem;

  @media screen and (max-width: ${Points.desktop}) {
    max-width: 80%;
  }
  @media screen and (max-width: ${Points.tablet}) {
    max-width: 85%;
  }
  @media screen and (max-width: ${Points.mobile}) {
    max-width: 95%;
  }
  @media screen and (max-width: ${Points.smallScreen}) {
    max-width: 100%;
  }
  @media screen and (max-width: ${Points.extraSmallScreen}) {
    max-width: ${Points.extraSmallScreen};
  }
`;

export const Grid = css`
  display: grid;
  gap: 1.5rem;
`;

export const Button = styled.button`
  position: relative;
  border-radius: ${Radius};
  background: ${({ background }) => (background ? Color.body_color : "none")};
  white-space: nowrap;
  padding-top: ${({ pt }) => (pt ? pt + "rem" : ".5rem")};
  padding-bottom: ${({ pb }) => (pb ? pb + "rem" : ".5rem")};
  padding-left: ${({ pl }) => (pl ? pl + "rem" : "1.5rem")};
  padding-right: ${({ pr }) => (pr ? pr + "rem" : "1.5rem")};
  font-size: 0.9rem;
  font-weight: bold;
  width: fit-content;
  color: ${({ inverse }) =>
    inverse ? Color.container_color : Color.first_color};
  outline: none;
  border: 1px solid
    ${({ inverse }) => (inverse ? Color.container_color : Color.first_color)};
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  transition: 0.2s all ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ center }) => (center ? "auto" : "0")};
  margin-right: ${({ center }) => (center ? "auto" : "0")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};

  &[disabled] {
    color: ${Color.text_color_light};
    border: 1px solid ${Color.text_color_light};
    &::before {
      background: ${Color.text_color_light};
      color: ${Color.text_color};
    }
  }

  svg {
    margin-left: 5px;
  }

  &::before {
    background: ${({ inverse, grad }) =>
      grad
        ? "linear-gradient(45deg," + Color.first_color + ",hsl(112,100%,42%))"
        : inverse
        ? Color.container_color
        : Color.first_color};
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 500%;
    height: 0%;
    transition: 0.2s all ease-in;
    z-index: -1;
  }

  &:hover:before {
    height: 500%;
  }
  &:hover {
    color: ${({ inverse }) =>
      !inverse ? Color.container_color : Color.first_color};
    border: 1px solid transparent;
  }
`;

export const Hr = styled.div`
  width: ${({ w }) => (w ? w + "%" : "75%")};
  height: 1px;
  margin: ${({ center }) => (center ? "0 auto" : "0 0 0 15px")};
  background-color: ${({ white }) =>
    white ? Color.container_color : Color.text_color};
  margin-top: ${({ mt }) => (mt > 0 ? mt + "rem" : "0")};
  margin-bottom: ${({ mb }) => (mb > 0 ? mb + "rem" : "0")};
`;

export const Anchor = styled(Link)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: ${Font.size.h1_font_size};
  padding: 0.5rem;
  cursor: pointer;
  z-index: ${Z_index.z_fixed};
  transform: scale(0);
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.5);

  &.show {
    transform: scale(1);
  }
`;

export const Label = styled.label`
  font-weight: ${({ fw }) =>
    fw === 1
      ? Font.weight.font_medium
      : fw === 2
      ? Font.weight.font_regular
      : Font.weight.font_semi_bold};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "1rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0")};
  ${({ grid }) =>
    grid === "true"
      ? `display: grid;
        grid-template-columns: 0.6rem 1fr;
        align-items: center;
        gap: 1rem;
        cursor: pointer;`
      : ""}
  text-align: ${({ talign }) => (talign ? talign : "")};

  span {
    /* Required (*) */
    color: red;
  }
`;

export const Select = styled.select`
  padding: ${({ pad }) => (pad ? pad : "1rem")};
  border-radius: ${Radius};
  border: none;
  background-color: ${Color.input_color};
  margin-bottom: 0.5rem;
  height: ${({ h }) => (h ? h : "")};
  width: ${({ w }) => (w ? w : "")};

  &:focus-visible {
    outline: 2px solid ${Color.blue_color};
  }
`;

export const Option = styled.option`
  background-color: ${({ checked }) =>
    checked ? Color.first_color : "transparent"} !important;
  color: ${({ checked }) => (checked ? "white " : "null")} !important;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;

  &[selected] {
    background-color: none;
  }
`;

export const Input = styled.input`
  padding: ${({ pad }) => (pad ? pad : "1rem")};
  border-radius: ${Radius};
  border: none;
  background-color: ${Color.input_color};
  margin-bottom: 0.5rem;
  height: ${({ h }) => (h ? h : "")};
  width: ${({ w }) => (w ? w : "")};

  /* Type Radio */
  &[type="radio"] {
    display: none;
  }

  &[type="radio"] + div {
    width: 1.25em;
    height: 1.25em;
    border: 3px solid #ddd;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  &[type="radio"] + div::after {
    content: "";
    background-color: ${Color.blue_color};
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transform: scale(0);
  }

  &[type="radio"]:checked + div::after {
    transform: scale(1);
  }

  /* Type Submit */
  &[type="submit"] {
    width: min-content;
    background-color: ${Color.blue_color};
    cursor: pointer;
    font-weight: ${Font.weight.font_semi_bold};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.21);

    &:hover {
      color: ${Color.container_color};
      background-color: ${Color.first_color};
    }
  }

  &::placeholder {
    color: ${Color.text_color_light};
  }

  &:focus-visible {
    outline: 2px solid ${Color.blue_color};
  }
`;

export const Textarea = styled.textarea`
  padding: 1rem;
  border-radius: ${Radius};
  border: none;
  background-color: ${Color.input_color};
  margin-bottom: 0.5rem;
  resize: vertical;
  min-height: 100px;

  &::placeholder {
    color: ${Color.text_color_light};
  }

  &:focus-visible {
    outline: 2px solid ${Color.blue_color};
  }
`;

export const STableContainer = styled.div`
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  padding: 0.5rem;
  background-color: ${Color.first_color};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.21);
  border-radius: ${Radius};

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0.09rem;
  }
`;

export const STable = styled.table`
  border-radius: ${Radius};
  border-collapse: collapse;
  overflow: hidden;
  width: ${({ w }) => (w ? w + "%" : "")};
  height: ${({ h }) => (h ? h + "%" : "")};

  @media screen and (max-width: ${Points.smallScreen}) {
    overflow: visible;
  }
`;

export const STHead = styled.thead`
  background-color: ${({ bgcolor }) =>
    bgcolor ? bgcolor : "hsl(165, 100%, 41%)"};
  margin: 0 auto;
  border-radius: ${Radius};
`;

export const STHeadTR = styled.tr``;

export const STH = styled.th`
  text-align: center;
  padding: 0.5rem 1rem;
  font-weight: ${Font.weight.font_semi_bold};
  text-transform: uppercase;
  color: ${({ txtcolor }) => (txtcolor ? txtcolor : Color.container_color)};
  border: 1px solid
    ${({ bordercolor }) => (bordercolor ? bordercolor : "transparent")};

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0.5rem 0.2rem;
    font-size: ${Font.size.smaller_font_size};
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0.5rem 0.03rem;
  }
`;

export const STBody = styled.tbody`
  margin: 0 auto;
`;

export const STBodyTR = styled.tr``;

export const STD = styled.td`
  text-align: ${({ txt_align }) => (txt_align ? txt_align : "center")};
  padding: 0.5rem 1rem;
  background-color: ${Color.container_color};
  border: ${({ bordercolor }) =>
    bordercolor
      ? "2px solid " + bordercolor
      : "1px solid " + Color.first_color};
  width: min-content;
  height: min-content;
  font-weight: ${({ bold }) =>
    bold ? Font.weight.font_semi_bold : Font.weight.font_regular};
  text-transform: ${({ txt_trsf }) =>
    txt_trsf === 1
      ? "lowercase"
      : txt_trsf === 2
      ? "capitalize"
      : txt_trsf === 3
      ? "uppercase"
      : ""};
  color: ${({ text_color }) => (text_color ? text_color : "")};

  & span {
    display: block;
    margin-bottom: ${({ spanmb }) => (spanmb ? spanmb + "rem" : "0rem")};
    margin-top: ${({ spanmt }) => (spanmt ? spanmt + "rem" : "0rem")};
    text-align: left;
    font-weight: ${({ spanbold }) =>
      spanbold ? Font.weight.font_semi_bold : Font.weight.font_regular};
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0.5rem 0.2rem;
    font-size: ${Font.size.smaller_font_size};
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0.5rem 0.03rem;
  }
`;
