import React from "react";
import { Acordion } from "./Acordion";
import { Color } from "../../style_config/VarStyle";
import { ScProgram } from "./ScprogramTableAcordion";
import {
  STable,
  STBody,
  STBodyTR,
  STD,
  STH,
  STHead,
  STHeadTR,
} from "../../style_config/UseStyle";

export const ProgramTableAcordion = ({ data, graduate = "" }) => {
  const titleAcordion = Object.keys(data);
  const titleTable = Object.keys(data.semestre_8[0]);

  return (
    <ScProgram>
      {titleAcordion.map((item, index) => {
        const dataItem = data[item];
        let total = 0;

        switch (graduate) {
          case "master1":
            if (index !== 2) {
              return (
                <TableReturn
                  key={index}
                  item={item}
                  titleTable={titleTable}
                  dataItem={dataItem}
                  total={total}
                />
              );
            } else return "";

          case "master2":
            if (index === 2) {
              return (
                <TableReturn
                  key={index}
                  item={item}
                  titleTable={titleTable}
                  dataItem={dataItem}
                  total={total}
                />
              );
            } else return "";

          default:
            return (
              <TableReturn
                key={index}
                item={item}
                titleTable={titleTable}
                dataItem={dataItem}
                total={total}
              />
            );
        }
      })}
    </ScProgram>
  );
};

const TableReturn = ({ item, titleTable, dataItem, total }) => {
  return (
    <Acordion data={item} fullwidth={false}>
      <STable w={100}>
        <STHead bgcolor="none">
          <STHeadTR>
            {titleTable.map((item2, index2) => {
              if (index2 === 0) {
                return (
                  <STH txtcolor={Color.first_color} key={index2}>
                    {item2.replace(/_/g, " ").replace("1", "'")}
                  </STH>
                );
              }
              return (
                <STH txtcolor={Color.first_color} key={index2}>
                  {item2}
                </STH>
              );
            })}
          </STHeadTR>
        </STHead>
        <STBody>
          {dataItem.map((obj, index3) => {
            total += obj.credits;
            return (
              <STBodyTR key={index3}>
                <STD bordercolor="rgba(245, 245, 245, 1)" txt_align="left">
                  {obj.intitules_des_unites_d1enseignement}
                </STD>
                <STD bordercolor="rgba(245, 245, 245, 1)">{obj.credits}</STD>
              </STBodyTR>
            );
          })}
          <STBodyTR>
            <STD
              bold={true}
              bordercolor="rgba(245, 245, 245, 1)"
              txt_align="left"
            >
              TOTAL
            </STD>
            <STD bold={true} bordercolor="rgba(245, 245, 245, 1)">
              {total}
            </STD>
          </STBodyTR>
        </STBody>
      </STable>
    </Acordion>
  );
};
