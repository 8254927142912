import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/**
 * @desc Loged a user
 * @type login
 * @action request action
 */
export const login = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("auth/login", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Register a user
 * @type register
 * @action request action
 */
export const register = createAsyncThunk(
  "register",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("auth/register", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc User logout
 * @type logout
 * @action request action
 */
export const logout = createAsyncThunk(
  "logout",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get("auth/logout");
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  credential: localStorage.getItem("credential")
    ? JSON.parse(localStorage.getItem("credential"))
    : null,
  isLoading: false,
  isSuccess: false,
  error: "",
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    // Login actions
    [login.pending]: (state) => {
      state.isLoading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      localStorage.setItem("credential", JSON.stringify(payload.user));
      state.credential = payload.user;
    },
    [login.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = payload.message;
    },

    // Logout actions
    [logout.pending]: (state) => {
      state.isLoading = true;
    },
    [logout.fulfilled]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      localStorage.removeItem("credential");
      state.credential = null;
    },
    [logout.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = payload.message;
    },

    // Register actions
    [register.pending]: (state) => {
      state.isLoading = true;
    },
    [register.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      localStorage.setItem("credential", JSON.stringify(payload.user));
      state.credential = payload.user;
    },
    [register.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = payload.message;
    },
  },
});

const authSlice = { name: AuthSlice.name, reducer: AuthSlice.reducer };
export default authSlice;
