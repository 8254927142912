/**
 *
 * scrollToTop.js
 * scroll restoration
 */

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLocation().pathname]);
  return <>{children}</>;
}
