import React from "react";
import { CheckedLink, LinkingData } from "./home/Slinking";

const CheckLink = ({ data, inverse, fs = "" }) => {
  return (
    <>
      {data.map((link, index) => (
        <LinkingData
          to={link.path}
          key={index}
          inverse={inverse ? "true" : "false"}
          fs={fs}
        >
          <CheckedLink inverse={inverse ? "true" : "false"} />
          <span>{link.name}</span>
        </LinkingData>
      ))}
    </>
  );
};

export default CheckLink;
