import React from "react";
import {
  BoxPub,
  BoxPubCategories,
  BoxPubSubTitle,
  BoxPubTitle,
  BoxPubYears,
} from "./home/ShomePub";

const BoxPublication = ({ data, color }) => {
  return (
    <>
      {data
        .sort((a, b) => b.years - a.years)
        // .filter(filtre)
        .map((content, i) => (
          <BoxPub to={content.path} key={i}>
            <BoxPubTitle
              color={color ? color : ""}
              dangerouslySetInnerHTML={{ __html: content.title }}
            ></BoxPubTitle>
            <BoxPubSubTitle>{content.subTitle}</BoxPubSubTitle>
            <BoxPubCategories>{content.categories}</BoxPubCategories>
            <BoxPubYears>
              {content.month ? content.month + " " : ""}
              {content.day ? content.day + ", " : ""}
              {content.years}
            </BoxPubYears>
          </BoxPub>
        ))}
    </>
  );
};

export default BoxPublication;
