import React, { useState } from "react";
import { FaMailBulk, FaPhoneAlt, FaTimes } from "react-icons/fa";
import {
  Content,
  Onglet,
  OngletElement,
  Separate,
  ShowBox,
  ShowContainer,
  ShowBackground,
  ContentBox,
  AnnuaireProfile,
  AnnuaireName,
  AnnuaireSpeciality,
  LabelP,
  EmailLink,
  AnnuaireStatus,
  AnnuaireTel,
  ContentEmailTel,
  AnnuaireEmail2,
  ProjectAndPubData,
  ProjectAndPub,
} from "../../pages/homepage/contact/Sannuaire";
import BoxPublication from "../BoxPublication";
import CheckedLink from "../ChekedLink";
import Contacter from "./Mailer";

export const ShowMore = ({ data, toogle }) => {
  const [active, setActive] = useState(1);
  return (
    <>
      <ShowBackground>
        <FaTimes onClick={toogle} />

        <ShowContainer>
          <ShowBox>
            {/* Les deux Onglets */}
            <Onglet>
              <OngletElement
                className={active === 1 ? "active" : ""}
                onClick={() => setActive(1)}
              >
                Détail
              </OngletElement>
              <OngletElement
                className={active === 2 ? "active" : ""}
                onClick={() => setActive(2)}
              >
                Contacter
              </OngletElement>
            </Onglet>

            <Separate center="true" />

            {/* Contenu Numero 1 */}
            <Content className={active === 1 ? "active" : ""}>
              {/* Contenu complet de l'utilisateur */}
              <ContentBox>
                <AnnuaireProfile>
                  <img src={data.img} alt="Profile" />
                </AnnuaireProfile>
                <AnnuaireName>{data.name}</AnnuaireName>
                <AnnuaireStatus>{data.status}</AnnuaireStatus>
                <AnnuaireSpeciality area="sct3">
                  <LabelP>Spécialité: </LabelP>
                  {data.speciality}
                </AnnuaireSpeciality>
                <ContentEmailTel>
                  <AnnuaireEmail2 area="">
                    <FaMailBulk />
                    <EmailLink href={`mailto:${data.email}`}>
                      {data.email}
                    </EmailLink>
                  </AnnuaireEmail2>
                  <AnnuaireTel area="">
                    <FaPhoneAlt />
                    <span>{data.tel}</span>
                  </AnnuaireTel>
                </ContentEmailTel>
              </ContentBox>

              {/* Ses projet en cours */}
              <ProjectAndPub>
                <h3>Thèses en cours</h3>
                <ProjectAndPubData>
                  {data.searchNow.length !== 0 ? (
                    <CheckedLink data={data.searchNow} inverse="true" />
                  ) : (
                    "Aucun resultat trouvée."
                  )}
                </ProjectAndPubData>
              </ProjectAndPub>

              {/* Ses Publication */}
              <ProjectAndPub>
                <h3>Publication</h3>
                <ProjectAndPubData>
                  {data.publication.length !== 0 ? (
                    <BoxPublication data={data.publication} />
                  ) : (
                    "Aucun resultat trouvée."
                  )}
                </ProjectAndPubData>
              </ProjectAndPub>
            </Content>

            {/* Contenu Numero 2 */}
            <Content className={active === 2 ? "active" : ""}>
              <Contacter data={data} />
            </Content>
          </ShowBox>
        </ShowContainer>
      </ShowBackground>
    </>
  );
};
