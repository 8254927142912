import React, { useState } from "react";
import {
  BoxContact,
  ContactTitle,
  FormContact,
  TextSend,
} from "../../pages/homepage/contact/Sannuaire";
import { Input, Label, Textarea } from "../../style_config/UseStyle";

const Mailer = ({ data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sujet, setSujet] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefaulte();
    console.log(name, email, sujet, message);
  };

  return (
    <>
      <BoxContact>
        <ContactTitle>Contacter {data.name}</ContactTitle>
        <FormContact action="" onSubmit={handleSubmit}>
          <Label ml={1} htmlFor="edit-name">
            Votre Nom <span>*</span>
          </Label>
          <Input
            type="text"
            placeholder="exemple: Eliot Jean"
            id="edit-name"
            name="user_name"
            onChange={(e) => setName(e.target.value)}
          />

          <Label ml={1} htmlFor="edit-email">
            Votre adresse de courriel <span>*</span>
          </Label>
          <Input
            type="email"
            placeholder="exemple: eliotjean@gmail.com"
            id="edit-email"
            name="user_email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextSend>
            <span>à</span>
            <p>
              {data.name} ({data.email})
            </p>
          </TextSend>

          <Label ml={1} htmlFor="edit-sujet">
            Sujet <span>*</span>
          </Label>
          <Input
            type="text"
            placeholder="Sujet du mail"
            id="edit-sujet"
            onChange={(e) => setSujet(e.target.value)}
          />

          <Label ml={1} htmlFor="edit-sujet">
            Message <span>*</span>
          </Label>
          <Textarea
            id="edit-sujet"
            placeholder="Votre message"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
          ></Textarea>

          <Input type="submit" value="Envoyer" />
        </FormContact>
      </BoxContact>
    </>
  );
};

export default Mailer;
