import { createGlobalStyle } from "styled-components";
import { IcoList } from "../data/assets";
import { Color, Font, Points } from "./VarStyle";

export const GlobalStyle = createGlobalStyle`
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.2s linear;
  user-select: none;
  line-height: 1.5rem;
}

#root{
  height: 100vh;
}

html{
  scroll-behavior: smooth;
}
body{
  font-family: ${Font.body_font};
  font-size: ${Font.size.normal_font_size};
  background-color: ${Color.container_color};
  color: ${Color.text_color};
}

li{
  list-style: none;

  &::before {
      content: url(${IcoList});
      margin-right: 1rem;
      font-weight: ${Font.weight.font_semi_bold};
      font-size: ${Font.size.h3_font_size};
    }
}

h1,h2,h3,h4,h5,h6,p,span{
  user-select: text;
}

h1,h2,h3,h4{
  font-weight: ${Font.weight.font_semi_bold};
  color: ${Color.title_color};
}

a{
  text-decoration: none;
  cursor: pointer;
}

img{
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: ${Points.tablet}){
  body{
    font-size: ${Font.size.normal_font_size};
  }
}
@media screen and (max-width: ${Points.mobile}){
  body{
    font-size: ${Font.size.small_font_size};
  }
}

::-webkit-scrollbar{
  width: 8px;
  border-radius: 25px;
  background-color: none;
}

::-webkit-scrollbar-thumb{
  width: 5px;
  background-color: ${Color.blue_color};
  border-radius: 25px;
}

::-webkit-scrollbar-track{
  background-color: none;
  border-radius: 25px;
}
`;
