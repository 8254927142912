import styled from "styled-components";
import { Color, Points, Radius } from "../../../style_config/VarStyle";

const S = {
  Auth: styled.section`
    height: 100%;
    width: 100%;
    position: relative;
  `,

  BlockStyle: styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: 1fr min-content;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40%;
    background: linear-gradient(45deg, #43e97b 0%, #00ffd0 100%);

    @media screen and (max-width: ${Points.smallScreen}) {
      width: 100%;
      height: 60px;
    }
  `,
  BlockForm: styled.div`
    position: absolute;
    top: 0;
    left: 40%;
    width: calc(100% - 40%);
    height: 100%;
    padding: 2rem 0;
    overflow-y: scroll;
    display: ${({ flex }) => (flex ? "flex" : null)};
    align-items: ${({ flex }) => (flex ? "center" : null)};

    @media screen and (max-width: ${Points.smallScreen}) {
      width: 100%;
      height: calc(100% - 60px);
      top: 60px;
      left: 0;
      right: 0;
    }
  `,
  Image: styled.div`
    width: 25vw;
    padding: 2rem;
    background: ${Color.container_color};
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    aspect-ratio: 1/1;
    border-radius: 50%;
    position: relative;
    z-index: 25;

    & img {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120%;
      height: 70%;
      backdrop-filter: blur(15px);
      border-top: 1px solid #fff;
      border-radius: ${Radius};
      z-index: 50;
    }

    @media screen and (max-width: ${Points.smallScreen}) {
      display: none;
    }
  `,
  Button: styled.button`
    padding-top: ${({ pt }) => (pt ? pt + "rem" : ".5rem")};
    padding-bottom: ${({ pb }) => (pb ? pb + "rem" : ".5rem")};
    padding-left: ${({ pl }) => (pl ? pl + "rem" : "1.5rem")};
    padding-right: ${({ pr }) => (pr ? pr + "rem" : "1.5rem")};
    font-size: 0.9rem;
    font-weight: bold;
    width: fit-content;
    background: none;
    outline: none;
    border: 2px solid ${Color.container_color};
    border-radius: ${Radius};
    color: ${Color.container_color};
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 40px;

    &::before {
      content: "";
      background: ${Color.container_color};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 300%;
      height: 0%;
      transition: 0.2s all ease-in;
      z-index: -1;
    }

    &:hover:before {
      height: 500%;
    }
    &:hover {
      color: ${Color.first_color};
      border: 2px solid transparent;
    }

    @media screen and (max-width: ${Points.smallScreen}) {
      margin-bottom: 0px;
    }
  `,
};
export default S;
