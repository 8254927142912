import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Color, Font, Z_index } from "../../style_config/VarStyle";

export const Ssidebar = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${({ show }) => (show ? "0%" : "100%")};
  background-color: rgba(0, 0, 0, 0.11);
  backdrop-filter: blur(7px);
  z-index: ${Z_index.z_fixed};
`;

export const SidebarContainer = styled.div`
  height: 100%;
  width: fit-content;
  min-width: 177px;
  background-color: #fff;
  border-left: 2px solid rgba(0, 0, 0, 0.21);
  margin-left: auto;

  display: grid;
  grid-template: 9% 1fr / 1fr;
  grid-template-areas: "1" "2";
`;

export const CloseContent = styled.div`
  grid-area: 1;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 20px;

  svg {
    cursor: pointer;
  }
`;

export const SidebarMenu = styled.div`
  grid-area: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SidebarDropdown = styled.div`
  display: none;
  flex-direction: column;
  padding-left: 5px;
`;

export const DropName = styled.div`
  color: ${Color.text_color};
  font-weight: ${Font.weight.font_medium};
  font-size: ${Font.size.normal_font_size};

  &.display ~ ${SidebarDropdown} {
    display: flex;
  }
  svg {
    font-size: 0.6rem;
    margin-left: 5px;
  }

  &:hover {
    color: ${Color.first_color};
    svg {
      transform: rotate(-180deg);
    }
  }
`;

export const Sidebarlink = styled(NavLink)`
  color: ${Color.text_color};
  font-weight: ${Font.weight.font_medium};
  font-size: ${Font.size.normal_font_size};
  transition: 0.3s all linear;

  &.active {
    color: ${Color.first_color_alt};
  }

  &:hover {
    color: ${Color.first_color};
  }
`;

export const Item = styled.div`
  display: block;
  transition: 0.3s all linear;
  padding: 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.21); */
  cursor: pointer;

  &.display ${SidebarDropdown} {
    display: flex;
  }
  &:hover {
    ${DropName} {
      color: ${Color.first_color};

      svg {
        transform: rotate(-180deg);
      }
    }

    ${Sidebarlink} {
      color: ${Color.first_color};
    }
  }
`;
