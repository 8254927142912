import React from "react";
import { FaTimes } from "react-icons/fa";
import { SlArrowDown } from "react-icons/sl";
import Data from "../../data/DataClient";
import { Dropdownlink } from "./Snavbar";
import { Hr } from "../../style_config/UseStyle";
import { useToogle } from "../../hooks";
import {
  CloseContent,
  DropName,
  Item,
  SidebarContainer,
  SidebarDropdown,
  Sidebarlink,
  SidebarMenu,
  Ssidebar,
} from "./Ssidebar";

const { navbar } = Data;

const DropdownSidebar = ({ link }) => {
  return (
    <>
      <DropName>
        {link.name}
        <SlArrowDown />
      </DropName>
      <SidebarDropdown>
        {link.drop.map((dropLink, key) => (
          <Dropdownlink key={key} to={dropLink.path}>
            {dropLink.name}
          </Dropdownlink>
        ))}
      </SidebarDropdown>
    </>
  );
};

export default function Sidebar({ show, setShow, setAnnim }) {
  const [display, setDisplay] = useToogle(false);
  const handleDisplay = function (e) {
    if (display) {
      setDisplay();
      e.target.classList.remove("display");
    } else {
      setDisplay();
      e.target.classList.add("display");
    }
  };
  return (
    <>
      <Ssidebar show={show}>
        <SidebarContainer>
          <CloseContent
            onClick={(_) => {
              setAnnim();
              setShow();
            }}
          >
            <FaTimes />
          </CloseContent>
          <SidebarMenu>
            {navbar.menu.map((link, index) => (
              <div key={index}>
                <Item onClick={handleDisplay}>
                  {link.down ? (
                    <DropdownSidebar link={link} />
                  ) : (
                    <Sidebarlink to={link.path}>{link.name}</Sidebarlink>
                  )}
                </Item>
                <Hr />
              </div>
            ))}
          </SidebarMenu>
        </SidebarContainer>
      </Ssidebar>
    </>
  );
}
