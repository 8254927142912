import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth.slice";
import userSlice from "./user.slice";
import articleSlice from "./article.slice";

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [articleSlice.name]: articleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: false,
});

export default function StoreProvider({ children }) {
  return <Provider store={store}>{children}</Provider>;
}
