import styled from "styled-components";
import { Container, Section } from "../../../style_config/UseStyle";
import {
  Color,
  Font,
  Header_height,
  Radius,
} from "../../../style_config/VarStyle";

export const ScFormation = styled(Section)`
  padding-top: 0;
  margin-top: ${Header_height};
  min-height: 100vh;
  max-height: fit-content;
  height: fit-content;
  background: ${Color.container_color};
  position: relative;
  z-index: 45;
`;

export const ScFormationTitle = styled.div`
  height: 250px;
  background-color: rgba(30, 30, 30, 0.99);
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    border-top: 20px solid #fff;
    border-right: 25px solid transparent;
    border-left: 25px solid transparent;
    border-bottom: 25px solid transparent;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
`;

export const ScTitle = styled.h1`
  color: ${({ txt_color }) => (txt_color ? txt_color : Color.blue_color)};
  font-size: ${({ size }) => (size ? size : Font.size.big_font_size)};
  text-transform: ${({ txt_case }) =>
    txt_case === 1
      ? "lowercase"
      : txt_case === 2
      ? "capitalize"
      : txt_case === 3
      ? "uppercase"
      : "none"};
  line-height: ${({ lh }) => (lh ? "1.5rem" : "2.5rem")};
  padding: ${({ pad }) => (pad ? pad + "rem" : "none")};
  text-align: ${({ txt_align }) => (txt_align ? txt_align : "")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
`;

export const ScFormationContainer = styled(Container)`
  margin: 1rem auto;
`;

export const TextData = styled.p`
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  color: ${({ black }) => (black ? "black" : "")};
  ${({ first_letter }) =>
    first_letter
      ? `&:first-letter {
            font-weight: ${Font.weight.font_semi_bold};
            font-size: ${Font.size.h3_font_size};
          }`
      : ""};
`;

export const UlList = styled.ul`
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};

  & li {
    margin-bottom: ${({ liMb }) => (liMb ? liMb + "rem" : "0rem")};
    margin-top: ${({ liMt }) => (liMt ? liMt + "rem" : "0rem")};
    margin-left: ${({ liMl }) => (liMl ? liMl + "rem" : "0rem")};
    margin-right: ${({ liMr }) => (liMr ? liMr + "rem" : "0rem")};

    & span {
      font-weight: ${({ spanbold }) =>
        spanbold ? Font.weight.font_semi_bold : Font.weight.font_regular};
      color: black;
    }
    & p {
      font-weight: ${({ pbold }) =>
        pbold ? Font.weight.font_semi_bold : Font.weight.font_regular};
    }
  }
`;

export const ScFormationBlock = styled.div`
  background-color: ${Color.container_color};
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-bottom-right-radius: ${Radius};
  border-bottom-left-radius: ${Radius};
  position: relative;

  &::after {
    content: "";
    background: linear-gradient(
      45deg,
      ${Color.first_color},
      hsl(112, 100%, 42%)
    );
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 8px;
    width: 100%;
    border-top-left-radius: ${Radius};
    border-top-right-radius: ${Radius};
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  }
`;
