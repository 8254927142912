import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import {
  Color,
  Font,
  Header_height,
  Points,
  Radius,
} from "../../../style_config/VarStyle";
import { Container, Section } from "../../../style_config/UseStyle";

export const SCTheses = styled(Section)`
  margin-top: ${Header_height};
  height: fit-content;
  min-height: 100vh;
  background: ${Color.bg_color};
  padding-top: 1rem;
`;

export const SCThesesContainer = styled(Container)``;

export const SCTTitle = styled.h1`
  font-size: ${({ size }) => (size ? size : Font.size.big_font_size)};
  color: ${({ txt_color }) =>
    txt_color === "gradient"
      ? "transparent"
      : txt_color
      ? txt_color
      : Color.title_color};
  ${({ txt_color }) =>
    txt_color === "gradient"
      ? `background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;`
      : ""}
  background-image: ${({ cat, txt_color }) =>
    txt_color === "gradient"
      ? cat
        ? "linear-gradient(45deg," + Color.first_color + ",hsl(112,100%,42%))"
        : "linear-gradient(45deg," +
          Color.first_color_alt +
          ", hsl(42,100%,50%))"
      : ""};
  text-transform: ${({ txt_case }) =>
    txt_case === 1
      ? "lowercase"
      : txt_case === 2
      ? "capitalize"
      : txt_case === 3
      ? "uppercase"
      : "none"};
  line-height: ${({ lh }) => (lh ? "1.5rem" : "2.5rem")};
  padding: ${({ pad }) => (pad ? pad + "rem" : "none")};
  text-align: ${({ txt_align }) => (txt_align ? txt_align : "")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  background-color: ${({ bgc }) => (bgc ? bgc : "none")};
  box-shadow: 0 2px 3px
    ${({ shadow_box_color }) =>
      shadow_box_color ? "rgba(0, 0, 0, 0.1)" : "transparent"};
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "opt0 opt1";
  margin: 1rem 0;
  grid-gap: 1rem;

  @media screen and (max-width: ${Points.mobile}) {
    grid-template-columns: 1fr;
    grid-template-areas: "opt1" "opt0";
  }
`;

export const GridLeft = styled.div`
  grid-area: opt0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

export const GridRight = styled.div`
  grid-area: opt1;
  padding: 1rem;
  background-color: ${Color.container_color};
  height: min-content;
  width: min-content;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: ${Radius};
  height: fit-content;
  max-width: 300px;
  min-width: 200px;
  position: relative;

  @media screen and (max-width: ${Points.mobile}) {
    margin: 0 auto;
  }
`;

export const SCTGDataContainer = styled.div;

export const SCTData = styled.a`
  background-color: ${Color.container_color};
  padding: 1rem;
  border-top-right-radius: ${Radius};
  border-bottom-right-radius: ${Radius};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  position: relative;

  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};

  &::after {
    content: "";
    background: linear-gradient(
      45deg,
      ${({ cat }) => (cat ? Color.first_color : Color.first_color_alt)},
      ${({ cat }) => (cat ? "hsl(112,100%,42%)" : "hsl(42,100%,50%)")}
    );
    position: absolute;
    left: -5px;
    top: 0;
    width: 5px;
    height: 100%;
    border-top-left-radius: ${Radius};
    border-bottom-left-radius: ${Radius};
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  }

  &:hover {
    background-color: rgb(245, 245, 245);
  }
`;

export const SCTText = styled.p`
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  font-weight: ${({ fw }) => (fw ? fw : "")};
  color: ${({ txt_color }) => (txt_color ? txt_color : Color.title_color)};
`;

export const SCYears = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const SCYData = styled.div`
  padding: 0.2rem 1rem;
  background: linear-gradient(
    45deg,
    rgba(16, 164, 164, 0.21),
    rgba(255, 255, 255, 0.5)
  );
  border-radius: ${Radius};
  text-align: center;
  color: ${Color.text_color};
  cursor: pointer;
  font-weight: ${Font.weight.font_semi_bold};

  &:hover,
  &.active {
    background: linear-gradient(
      45deg,
      ${Color.first_color_alt},
      hsl(42, 100%, 50%)
    );
    color: ${Color.container_color};
  }
`;

export const SCTCloseIcon = styled(FaTimes)`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  cursor: ${({ disp }) => (disp ? "pointer" : "none")};
  padding: 0.3rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: ${Color.title_color};
  display: ${({ disp }) => (disp === "true" ? "inline" : "none")};

  &:hover {
    color: ${Color.container_color};
    background-color: ${Color.title_color};
  }
`;
