import styled, { keyframes } from "styled-components";
import { Color } from "../../style_config/VarStyle";

const loader = keyframes`
  0%{transform:rotate(0turn)}
  50%{transform:rotate(.6turn)}
  90%{transform:rotate(.81turn)}
  100%{transform:rotate(1turn)}
`;

const S = {
  Spiner: styled.div`
    width: ${({ wh }) => (wh ? wh : "1.5rem")};
    height: ${({ wh }) => (wh ? wh : "1.5rem")};
    border: 3px solid transparent;
    border-top-color: ${({ color }) => (color ? color : Color.first_color)};
    border-right-color: ${({ color }) => (color ? color : Color.first_color)};
    border-radius: 50%;
    animation: ${loader} 0.75s linear infinite;
    aspect-ratio: 1;
  `,
};
export default S;
