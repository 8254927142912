import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/**
 * @desc Get auth a user data
 * @type getAuthUserData
 * @action request action
 */
export const getAuthUserData = createAsyncThunk(
  "getAuthUserData",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`users/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Update a user
 * @type updateUser
 * @action request action
 */
export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`users/${id}`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Delete a user
 * @type deleteUser
 * @action request action
 */
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.put(`users/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Get all users
 * @type getAllUser
 * @action request action
 */
export const getAllUser = createAsyncThunk(
  "getAllUser",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get(`users`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Update current user
 * @type updateCurrentUser
 * @action request action
 */
export const updateCurrentUser = createAsyncThunk(
  "updateCurrentUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(`users`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Update current user
 * @type updateCurrentUser
 * @action request action
 */
export const updatePasswordCurrentUser = createAsyncThunk(
  "updatePasswordCurrentUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(`users/password`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/**
 * @desc Delete current user
 * @type deleteCurrentUser
 * @action request action
 */
export const deleteCurrentUser = createAsyncThunk(
  "deleteCurrentUser",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.put(`users`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  search: "",
  user: {
    data: {},
    isLoading: false,
    isSuccess: false,
    error: "",
  },
  users: {
    data: [],
    isLoading: false,
    isSuccess: false,
    error: "",
  },
};

const UserSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    // Get auth user data actions
    [getAuthUserData.pending]: (state) => {
      state.user.isLoading = true;
      state.user.isSuccess = false;
    },
    [getAuthUserData.fulfilled]: (state, { payload }) => {
      state.user.data = payload;
      state.user.isLoading = false;
      state.user.isSuccess = true;
    },
    [getAuthUserData.rejected]: (state, { payload }) => {
      state.user.isLoading = false;
      state.user.isSuccess = false;
      state.user.error = payload.message;
    },

    // Get auth user data actions
    [getAllUser.pending]: (state) => {
      state.users.isLoading = true;
    },
    [getAllUser.fulfilled]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isSuccess = true;
      state.users.data = payload;
    },
    [getAllUser.rejected]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isSuccess = false;
      state.users.error = payload.message;
    },

    // Update user actions
    [updateUser.pending]: (state) => {
      state.users.isLoading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isSuccess = true;
      state.users.data[
        state.users.data.findIndex((el) => el === payload.user._id)
      ] = payload.user;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isSuccess = false;
      state.users.error = payload.message;
    },

    // Update current user actions
    [updateCurrentUser.pending]: (state) => {
      state.user.isLoading = true;
    },
    [updateCurrentUser.fulfilled]: (state, { payload }) => {
      state.user.isLoading = false;
      state.user.isSuccess = true;
      state.user.data = payload.user;
    },
    [updateCurrentUser.rejected]: (state, { payload }) => {
      state.user.isLoading = false;
      state.user.isSuccess = false;
      state.user.error = payload.message;
    },

    // Update password current user actions
    [updatePasswordCurrentUser.pending]: (state) => {
      state.user.isLoading = true;
    },
    [updatePasswordCurrentUser.fulfilled]: (state, { payload }) => {
      state.user.isLoading = false;
      state.user.isSuccess = true;
      state.user.data.password = payload.password;
    },
    [updatePasswordCurrentUser.rejected]: (state, { payload }) => {
      state.user.isLoading = false;
      state.user.isSuccess = false;
      state.user.error = payload.message;
    },

    // Delete a user actions
    [deleteUser.pending]: (state) => {
      state.users.isLoading = true;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isSuccess = true;
      state.users.data = state.users.data.filter(
        (el) => el === payload.user._id
      );
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.users.isLoading = false;
      state.users.isSuccess = false;
      state.users.error = payload.message;
    },

    // Delete current user actions
    [deleteCurrentUser.pending]: (state) => {
      state.user.isLoading = true;
    },
    [deleteCurrentUser.fulfilled]: (state, { payload }) => {
      state.user.isLoading = false;
      state.user.isSuccess = true;
      state.user.data = state.user.data.filter((el) => el === payload.user._id);
    },
    [deleteCurrentUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = payload.message;
    },
  },
});

export const { setSearch } = UserSlice.actions;

const userSlice = { name: UserSlice.name, reducer: UserSlice.reducer };
export default userSlice;
