import React from "react";
import Mailer from "../../../components/contact/Mailer";
import { ScontactWe } from "./ScontactWe";
import { Container } from "../../../style_config/UseStyle";
import { useNavColor } from "../../../hooks";

const ContactWe = ({ setnav }) => {
  useNavColor(true, setnav);

  return (
    <>
      <ScontactWe>
        <Container>
          <Mailer
            data={{ name: "URGPGE Laboratoire", email: "contact@urgpge-ua.mg" }}
          />
        </Container>
      </ScontactWe>
    </>
  );
};

export default ContactWe;
