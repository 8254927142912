import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import RouteMap from "./Routes";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./scrollToTop";

export default function App() {
  const [colorNav, setColorNav] = useState(false);
  return (
    <>
      <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
      <ScrollToTop>
        <Routes>
          {RouteMap.map(({ arg, layout, child, props }, i) => (
            <Route
              key={i}
              {...props}
              element={arg ? layout(colorNav) : layout()}
            >
              {child?.length && (
                <>
                  {child?.map(({ component, ...propsChild }, i2) => (
                    <Route
                      key={i2}
                      {...propsChild}
                      element={arg ? component(setColorNav) : component()}
                    />
                  ))}
                </>
              )}
            </Route>
          ))}
        </Routes>
      </ScrollToTop>
    </>
  );
}
