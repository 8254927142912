import React from "react";
import { Helmet } from "react-helmet";
import { ProgramTableAcordion } from "../../../components/formation/ProgramTableAcordion";
import DataClient from "../../../data/DataClient";
import { useNavColor } from "../../../hooks";
import { Color, Font } from "../../../style_config/VarStyle";
import {
  ScFormation,
  ScFormationContainer,
  ScFormationTitle,
  ScTitle,
  TextData,
  UlList,
  ScFormationBlock,
} from "./Sformation";

const Formation = ({ setnav }) => {
  useNavColor(true, setnav);
  const { formation } = DataClient;
  return (
    <>
      <Helmet>
        <title>Formation - Laboratoire URGPGE</title>
        <meta
          name="keywords"
          content="Formation,Programme,Compétences acquis après,Poursuites d’étude,Présentation,débouchés,Unité"
        />
        <meta
          name="description"
          content="La première année de master est accessible sur dossier aux candidats ayant un diplôme de licence académique en chimie ou équivalent."
        />
      </Helmet>
      <ScFormation>
        <ScFormationTitle>
          <ScTitle pad={1} txt_align="center">
            {formation.title_page}
          </ScTitle>
        </ScFormationTitle>

        {/* presentation master */}
        <ScFormationContainer>
          <ScTitle mb={1} txt_color={Color.title_color}>
            {formation.title}
          </ScTitle>
          <TextData black={true}>
            {formation.master_present.title}
            {" :"}
          </TextData>
          <UlList spanbold={true} liMt={1} liMb={1} liMl={2}>
            {formation.master_present.data.map((obj, index) => (
              <li key={index}>
                <span>
                  {obj.title}
                  {" : "}
                </span>
                <p>{obj.text}</p>
              </li>
            ))}
          </UlList>

          {/* programme */}
          <ScTitle mb={1.5} txt_color={Color.title_color}>
            {formation.programme.title}
          </ScTitle>
          {formation.master_present.suplement.map((obj, i) => (
            <TextData black={true} mb={i === 1 ? 1 : 0} key={i}>
              {obj.text}
              {" : "}
              <span>{obj.span}</span>
            </TextData>
          ))}
          <ProgramTableAcordion data={formation.programme.table} />

          {/* presentation Apres */}
          <ScTitle mb={1.5} txt_color={Color.title_color}>
            {formation.apres.title}
          </ScTitle>
          <ScFormationBlock>
            {/* present Competence */}
            <ScTitle
              size={Font.size.h3_font_size}
              txt_color={Color.title_color}
              lh={true}
              mb={1}
              mt={1}
            >
              {formation.apres.competence.title}
            </ScTitle>
            <UlList liMl={1.5}>
              {formation.apres.competence.data.map((obj, index) => (
                <li key={index}>{obj}</li>
              ))}
            </UlList>

            {/* debouche et apres etude */}
            <ScTitle
              size={Font.size.h3_font_size}
              txt_color={Color.title_color}
              lh={true}
              mb={1}
              mt={1}
            >
              {formation.apres.poursuit.title}
            </ScTitle>

            <TextData ml={1.5}>{formation.apres.poursuit.text}</TextData>

            {/* admission */}
            <ScTitle
              size={Font.size.h3_font_size}
              txt_color={Color.title_color}
              lh={true}
              mb={1}
              mt={1}
            >
              {formation.admission.title}
            </ScTitle>
            <TextData ml={1.5}>{formation.admission.text}</TextData>
          </ScFormationBlock>
        </ScFormationContainer>
      </ScFormation>
    </>
  );
};

export default Formation;
