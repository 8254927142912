import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/**
 * @desc Get all users
 * @type getAllArticle
 * @action request action
 */
export const getAllArticle = createAsyncThunk(
  "getAllArticle",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get(`articles`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  data: [],
  isLoading: false,
  isSuccess: false,
  error: "",
};

const ArticleSlice = createSlice({
  name: "articles",
  initialState,
  extraReducers: {
    // Get all articles action
    [getAllArticle.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllArticle.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getAllArticle.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = payload.message;
    },
  },
});

const articleSlice = { name: ArticleSlice.name, reducer: ArticleSlice.reducer };
export default articleSlice;
