import Home from "./pages/homepage/home/Home";
import Presentation from "./pages/homepage/presentation/Presentation";
import Formation from "./pages/homepage/formation/Formation";
import FormationDynamic from "./pages/homepage/formation/FormationDynamic";
import Search from "./pages/homepage/search/Search";
import Theses from "./pages/homepage/theses/Theses";
import Articles from "./pages/homepage/articles/Articles";
import DynamicArticle from "./pages/homepage/articles/DynamicArticle";
import Annuaire from "./pages/homepage/contact/Annuaire";
import ContactWe from "./pages/homepage/contact/ContactWe";
import Auth from "./pages/homepage/connection/Auth";
import Layout from "./layout/Layout";
import NotFound from "./pages/notFound/NotFound";
import AuthLayout from "./layout/AuthLayout";
// import DashboardLayout from "./layout/DashboardLayout";
// import DashboardHome from "./pages/dashboard/home";
// import Profile from "./pages/dashboard/profile";
// import ArticleDashboard from "./pages/dashboard/article";

const RoutesMap = [
  {
    arg: true,
    layout: (nav) => <Layout nav={nav} />,
    props: { path: "/", exact: true },
    child: [
      {
        index: true,
        component: (nav) => <Home setnav={nav} />,
      },
      {
        path: "/presentation",
        exact: true,
        component: (nav) => <Presentation setnav={nav} />,
      },
      {
        path: "/formation",
        exact: true,
        component: (nav) => <Formation setnav={nav} />,
      },
      {
        path: "/formation/:graduate",
        exact: true,
        component: (nav) => <FormationDynamic setnav={nav} />,
      },
      {
        path: "/recherche",
        exact: true,
        component: (nav) => <Search setnav={nav} />,
      },
      {
        path: "/theses",
        exact: true,
        component: (nav) => <Theses setnav={nav} />,
      },
      {
        path: "/articles",
        exact: true,
        component: (nav) => <Articles setnav={nav} />,
      },
      {
        path: "/articles/:id",
        exact: true,
        component: (nav) => <DynamicArticle setnav={nav} />,
      },
      {
        path: "/contact/annuaire",
        exact: true,
        component: (nav) => <Annuaire setnav={nav} />,
      },
      {
        path: "/contact/nous-contactez",
        exact: true,
        component: (nav) => <ContactWe setnav={nav} />,
      },
    ],
  },
  {
    arg: false,
    layout: () => <AuthLayout />,
    props: { path: "/auth", exact: true },
    child: [
      {
        index: true,
        component: () => <Auth />,
      },
    ],
  },
  // {
  //   arg: false,
  //   layout: () => <DashboardLayout />,
  //   props: { path: "/dashboard", exact: true },
  //   child: [
  //     {
  //       index: true,
  //       component: () => <DashboardHome />,
  //     },
  //     {
  //       path: "profile",
  //       exact: true,
  //       component: () => <Profile />,
  //     },
  //     {
  //       path: "article",
  //       exact: true,
  //       component: () => <ArticleDashboard />,
  //     },
  //   ],
  // },
  {
    arg: false,
    layout: () => <NotFound />,
    props: { path: "*" },
    child: [],
  },
];

export default RoutesMap;
