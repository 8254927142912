import React from "react";
import { PubContainer, PubSection, PubTitle } from "./ShomePub";
import DataClient from "../../data/DataClient";
import { Button, Hr } from "../../style_config/UseStyle";
import { Link } from "react-router-dom";
import { useToogle } from "../../hooks";
import { ArrowForward, ArrowRight } from "./Shero";
import BoxPublication from "../BoxPublication";

const HomePub = () => {
  const { articleFixture } = DataClient;
  const [hover, setHover] = useToogle(false);
  return (
    <>
      <PubSection>
        <PubTitle>{articleFixture.title}</PubTitle>
        <Hr center="true" white="true" mt={1.5} mb={2} />
        <PubContainer>
          <BoxPublication
            data={articleFixture.data
              .sort((a, b) => b.date.getTime() - a.date.getTime())
              .slice(0, 5)}
          />
          <Button
            as={Link}
            to={articleFixture.btn.path}
            onMouseEnter={setHover}
            onMouseLeave={setHover}
            center="true"
            inverse="true"
          >
            {articleFixture.btn.text}
            {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </PubContainer>
      </PubSection>
    </>
  );
};

export default HomePub;
