import React, { useEffect, useState } from "react";
import {
  CarouselWraper,
  Dot,
  Dots,
  SliderArrowLeft,
  SliderArrowRight,
  SliderItem,
  Stylecarousel,
} from "./Scarousel";
import { useSetIntervale } from "../../hooks";

export default function Carousel({
  children = "",
  show = 1,
  infiniteLoop = false,
  autoplay = false,
  delay = 1,
  styleImg = false,
  dot = true,
}) {
  const [state, setState] = useState({
    currentIndex: infiniteLoop ? show : 0,
    length: children !== "" ? children.length : 0,
    isRepeating: infiniteLoop && children !== "" && children.length > show,
    transition: true,
    touchPosition: null,
    Autoplay: autoplay,
  });
  const {
    currentIndex,
    length,
    isRepeating,
    transition,
    touchPosition,
    Autoplay,
  } = state;

  // Set autoplay slider
  useSetIntervale(() => {
    if (Autoplay && isRepeating) {
      next();
    }
  }, delay * 1000);

  // Set the length to match current children from props
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      length: children.length,
      isRepeating: infiniteLoop && children.length > show,
    }));
  }, [children, infiniteLoop, show]);

  const next = () => {
    if (isRepeating || currentIndex < length - show) {
      setState((prev) => ({
        ...prev,
        currentIndex: currentIndex + 1,
        transition: true,
      }));
    }
  };

  const prev = () => {
    if (isRepeating || currentIndex > 0) {
      setState((prev) => ({
        ...prev,
        currentIndex: currentIndex - 1,
        transition: true,
      }));
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setState((prev) => ({
      ...prev,
      touchPosition: touchDown,
    }));
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setState((prev) => ({
      ...prev,
      touchPosition: null,
    }));
  };

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex <= 0) {
        setState((prev) => ({
          ...prev,
          transition: false,
          currentIndex: length,
        }));
      } else if (currentIndex >= length + show) {
        setState((prev) => ({
          ...prev,
          transition: false,
          currentIndex: show,
        }));
      }
    }
  };

  const renderExtraPrev = () => {
    let output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index]);
    }
    output.reverse();
    return output;
  };

  const renderExtraNext = () => {
    let output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[index]);
    }
    return output;
  };

  const dotIsActive = (index) => {
    return (
      index === currentIndex ||
      (currentIndex === show - 1 && index === length + show - 1) ||
      (currentIndex === show - 2 && index === length + show - 2) ||
      (currentIndex === show - 3 && index === length + show - 3) ||
      (currentIndex === show - 4 && index === length + show - 4) ||
      (currentIndex === show - 5 && index === length + show - 5) ||
      (currentIndex === show - 6 && index === length + show - 6) ||
      (currentIndex > length + show && index === 0)
    );
  };

  const handleClickDot = (index) => {
    setState((prev) => ({
      ...prev,
      currentIndex: index,
    }));
  };

  return (
    <Stylecarousel
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onMouseEnter={(_) =>
        setState((prev) => ({
          ...prev,
          Autoplay: false,
        }))
      }
      onMouseLeave={(_) =>
        setState((prev) => ({
          ...prev,
          Autoplay: autoplay ? true : false,
        }))
      }
    >
      {(isRepeating || currentIndex > 0) && <SliderArrowLeft onClick={prev} />}
      <CarouselWraper
        trl={currentIndex * (100 / show)}
        trs={transition}
        onTransitionEndCapture={() => handleTransitionEnd()}
      >
        {length > show &&
          isRepeating &&
          renderExtraPrev().map((item, i) => (
            <SliderItem styleImg={styleImg} key={i} show={show}>
              {item}
            </SliderItem>
          ))}
        {children.map((item, i) => (
          <SliderItem styleImg={styleImg} key={i} show={show}>
            {item}
          </SliderItem>
        ))}
        {length > show &&
          isRepeating &&
          renderExtraNext().map((item, i) => (
            <SliderItem styleImg={styleImg} key={i} show={show}>
              {item}
            </SliderItem>
          ))}
      </CarouselWraper>
      {(isRepeating || currentIndex < length - show) && (
        <SliderArrowRight onClick={next} />
      )}
      {dot && (
        <Dots>
          {Array.from({ length: length + show }, (_, i) => i + show).map(
            (i) => {
              return i < length + show ? (
                <Dot
                  key={i}
                  className={dotIsActive(i) ? "active" : ""}
                  onClick={(_) => handleClickDot(i)}
                />
              ) : null;
            }
          )}
        </Dots>
      )}
    </Stylecarousel>
  );
}
