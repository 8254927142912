import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { GlobalStyle } from "./style_config/GlobalStyle";
import { HOST_SERVER } from "./config";
import App from "./App";
import StoreProvider from "./store";

axios.defaults.baseURL = `${HOST_SERVER}/api`;
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider>
      <Router>
        <Helmet>
          <title>Laboratoire URGPGE</title>
          <meta
            name="keywords"
            content="urgpge,laboratoire,chimie,antananarivo,master,génie,procédé,eau,environnement,formation,publication,recherche"
          />
          <meta name="theme-color" content="#d3cce3" />
          <meta
            name="description"
            content="Le centre de Recherche et d’Etudes en Génie de l’Eau et de l’Environnement CREG2E est un laboratoire d’analyses environnementales.Situé à Antananarivo,Ankatso,faculté des sciences"
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        </Helmet>
        <GlobalStyle />
        <App />
      </Router>
    </StoreProvider>
  </React.StrictMode>
);
