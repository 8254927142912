import React from "react";
import { SCYData, SCYears } from "../../pages/homepage/theses/Stheses";

export default function FilterYears({ data, isChecked, oneSelect }) {
  const dataMap = data.reduce((acc, curr) => {
    let currDate;
    if (typeof curr.date === "object" && curr.date.length) {
      currDate = curr.date.map((el) => el.getFullYear()).join(" - ");
    } else {
      currDate = curr.date.getFullYear();
    }
    return acc.includes(currDate) ? [...acc] : [...acc, currDate];
  }, []);
  return (
    <SCYears>
      {dataMap
        .sort((a, b) => b - a)
        .map((years, i) => (
          <SCYData
            key={i}
            data-value={years}
            onClick={oneSelect}
            className={isChecked(years) ? "active" : ""}
          >
            {years}
          </SCYData>
        ))}
    </SCYears>
  );
}
