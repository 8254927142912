import React, { useState } from "react";
import {
  ScAcordion,
  ScAcordionData,
  ScAcordionHeader,
  ScIconArrow,
} from "./ScAcordion";

export const Acordion = ({ children, data, fullwidth = false }) => {
  const [state, setState] = useState({
    iconUp: false,
  });
  const { iconUp } = state;

  const handleClick = (e) => {
    setState((prev) => ({
      ...prev,
      iconUp: !iconUp,
    }));
  };

  return (
    <ScAcordion bgcolor={iconUp.toString()}>
      <ScAcordionHeader onClick={handleClick} bgcolor={iconUp.toString()}>
        {data.replace("_", " ")}
        <ScIconArrow icon_up={iconUp.toString()} />
      </ScAcordionHeader>
      <ScAcordionData
        down={iconUp.toString()}
        pl={fullwidth ? "0" : ""}
        pr={fullwidth ? "0" : ""}
        pb={fullwidth ? "0" : ""}
      >
        {children}
      </ScAcordionData>
    </ScAcordion>
  );
};
