import styled from "styled-components";
import { MdOpenInNew } from "react-icons/md";
import {
  Color,
  Font,
  Header_height,
  Points,
  Radius,
} from "../../../style_config/VarStyle";
import { Container, Section } from "../../../style_config/UseStyle";

export default {
  DynamicArticle: styled(Section)`
    padding-top: calc(${Header_height} + 1rem);
    min-height: 100vh;
    max-height: fit-content;
    height: fit-content;
    background: ${Color.bg_color};
  `,
  Container: styled(Container)`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr max-content;
    grid-template-areas: "opt0 opt0" "opt1 opt2";

    @media screen and (max-width: ${Points.smallScreen}) {
      grid-template-columns: 1fr;
      grid-template-areas: "opt0" "opt1" "opt2";
    }
  `,
  GridLeft: styled.div`
    grid-area: opt1;
    padding: 1rem;
    border: 1px solid rgba(170, 170, 170, 0.2);
    border-top: 5px solid ${Color.first_color};
    background-color: ${Color.container_color};
    border-radius: ${Radius};
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  `,
  GridRight: styled.div`
    grid-area: opt2;
    background-color: ${Color.container_color};
    padding: 1rem;
    border-radius: ${Radius};
    height: min-content;
    border: 1px solid rgba(170, 170, 170, 0.2);
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    position: relative;

    @media screen and (max-width: ${Points.smallScreen}) {
      width: 100%;
      max-width: 100%;
    }
  `,
  GridTop: styled.div`
    grid-area: opt0;
    background-color: ${Color.container_color};
    padding: 1rem;
    border-radius: ${Radius};
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  `,
  Title: styled.h1`
    color: ${({ txt_color }) => (txt_color ? txt_color : Color.blue_color)};
    font-size: ${({ size }) => (size ? size : Font.size.big_font_size)};
    text-transform: ${({ txt_case }) =>
      txt_case === 1
        ? "lowercase"
        : txt_case === 2
        ? "capitalize"
        : txt_case === 3
        ? "uppercase"
        : "none"};
    line-height: ${({ lh }) => (lh ? "1.5rem" : "2.5rem")};
    padding: ${({ pad }) => (pad ? pad + "rem" : "none")};
    text-align: ${({ txt_align }) => (txt_align ? txt_align : "")};
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  `,
  DataText: styled.p`
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
    text-align: ${({ txt_align }) => (txt_align ? txt_align : "left")};

    &::first-letter {
      font-weight: ${({ fl }) => (fl ? "" : Font.weight.font_semi_bold)};
      font-size: ${({ fl }) => (fl ? "" : Font.size.h2_font_size)};
      color: ${({ fl }) => (fl ? "" : Color.title_color)};
    }
  `,
  Icon: styled(MdOpenInNew)`
    color: ${Color.first_color};
    margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
    margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
    margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
    margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
    font-size: ${Font.size.h2_font_size};
  `,
  OpenLink: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & span {
      text-align: justify;
    }
  `,
};
