import React from "react";
import DataClient from "../../data/DataClient";
import { Font } from "../../style_config/VarStyle";
import { Hr } from "../../style_config/UseStyle";
import CheckLink from "../ChekedLink";
import {
  FullContainer,
  FullData,
  LinkingSection,
  LinkingTitle,
  FullBlock,
  DataContainer,
  DataBox,
  Data,
  LinkingSubTitle,
} from "./Slinking";

const Linking = () => {
  const { searchFixture, homeFormation } = DataClient;
  const { search1, search2 } = searchFixture.reduce(
    (acc, curr) => {
      if (curr.categories === "Génie des procédés") {
        return {
          search1: {
            ...acc.search1,
            content: [
              ...acc.search1.content,
              { name: curr.description, path: "/recherche" },
            ],
          },
          search2: { ...acc.search2 },
        };
      } else {
        return {
          search1: { ...acc.search1 },
          search2: {
            ...acc.search2,
            content: [
              ...acc.search2.content,
              { name: curr.description, path: "/recherche" },
            ],
          },
        };
      }
    },
    {
      search1: { title: "Génie des procédés", content: [] },
      search2: { title: "Eau et environnement", content: [] },
    }
  );
  return (
    <LinkingSection>
      <FullBlock>
        <FullContainer>
          <LinkingTitle>{homeFormation.title}</LinkingTitle>
          <FullData>
            <CheckLink data={homeFormation.content} />
          </FullData>
        </FullContainer>
      </FullBlock>
      <DataContainer>
        <DataBox>
          <LinkingTitle inverse="gradient" mb={0.2}>
            {search1.title}
          </LinkingTitle>
          <LinkingSubTitle mb={1}>Thèses en cours</LinkingSubTitle>
          <Hr center="true" />
          <Data>
            <CheckLink
              fs={Font.size.small_font_size}
              data={search1.content}
              inverse="true"
            />
          </Data>
        </DataBox>
        <DataBox>
          <LinkingTitle inverse="gradient" mb={0.2}>
            {search2.title}
          </LinkingTitle>
          <LinkingSubTitle mb={1}>Théses en cours</LinkingSubTitle>
          <Hr center="true" />
          <Data>
            <CheckLink
              fs={Font.size.small_font_size}
              data={search2.content}
              inverse="true"
            />
          </Data>
        </DataBox>
      </DataContainer>
    </LinkingSection>
  );
};

export default Linking;
