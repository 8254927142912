import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavColor } from "../../../hooks";
import { ProgramTableAcordion } from "../../../components/formation/ProgramTableAcordion";
import DataClient from "../../../data/DataClient";
import { Color, Font } from "../../../style_config/VarStyle";
import { ScFormationDynamic } from "./SformationDinamic";
import {
  ScFormationBlock,
  ScFormationContainer,
  ScFormationTitle,
  ScTitle,
  TextData,
  UlList,
} from "./Sformation";

export default function FormationDynamic({ setnav }) {
  useNavColor(true, setnav);
  const { formation } = DataClient;
  const { graduate } = useParams();

  return (
    <>
      <Helmet>
        <title>Formation - Laboratoire URGPGE</title>
        <meta
          name="keywords"
          content="Formation,Programme,Compétences acquis après,Poursuites d’étude,Présentation,débouchés,Unité"
        />
        <meta
          name="description"
          content="La première année de master est accessible sur dossier aux candidats ayant un diplôme de licence académique en chimie ou équivalent."
        />
      </Helmet>
      <ScFormationDynamic>
        <ScFormationTitle>
          <ScTitle pad={1} txt_align="center">
            {graduate === "master1"
              ? "Master I en Génie de l’Eau et génie de l’environnement"
              : "Master II en Génie de l’Eau et génie de l’environnement"}
          </ScTitle>
        </ScFormationTitle>

        {/* presentation master */}
        <ScFormationContainer>
          <ScTitle mb={1} txt_color={Color.title_color}>
            {formation.title}
          </ScTitle>
          <TextData black={true}>
            {formation.master_present.title}
            {" :"}
          </TextData>
          <UlList spanbold={true} liMt={1} liMb={1} liMl={2}>
            {formation.master_present.data.map((obj, index) => (
              <li key={index}>
                <span>
                  {obj.title}
                  {" : "}
                </span>
                <p>{obj.text}</p>
              </li>
            ))}
          </UlList>

          {/* programme */}
          <ScTitle mb={1.5} txt_color={Color.title_color}>
            {formation.programme.title}
          </ScTitle>
          {formation.master_present.suplement.map((obj, i) => (
            <TextData black={true} mb={i === 1 ? 1 : 0} key={i}>
              {obj.text}
              {" : "}
              <span>
                {i === 0 ? "1an" : graduate === "master1" ? "Bac +4" : obj.span}
              </span>
            </TextData>
          ))}
          <ProgramTableAcordion
            graduate={graduate}
            data={formation.programme.table}
          />

          {/* presentation Apres si graduate="master2" ou Condition d'acces si graduate="master1" */}
          <ScTitle mb={1.5} txt_color={Color.title_color}>
            {graduate === "master2"
              ? formation.apres.title
              : formation.admission.title}
          </ScTitle>
          <ScFormationBlock>
            {graduate === "master2" && (
              <>
                {/* presentation Competence si graduate="master2" */}
                <ScTitle
                  size={Font.size.h3_font_size}
                  txt_color={Color.title_color}
                  lh={true}
                  mb={1}
                  mt={1}
                >
                  {formation.apres.competence.title}
                </ScTitle>
                <UlList liMl={1.5}>
                  {formation.apres.competence.data.map((obj, index) => (
                    <li key={index}>{obj}</li>
                  ))}
                </UlList>

                {/* debouche et apres etude si graduate="master2" */}
                <ScTitle
                  size={Font.size.h3_font_size}
                  txt_color={Color.title_color}
                  lh={true}
                  mb={1}
                  mt={1}
                >
                  {formation.apres.poursuit.title}
                </ScTitle>
                <TextData ml={1.5}>{formation.apres.poursuit.text}</TextData>
              </>
            )}

            {/* admission si graduate="master1" */}
            {graduate === "master1" && (
              <TextData ml={1.5} mb={0.5}>
                {formation.admission.text}
              </TextData>
            )}
          </ScFormationBlock>
        </ScFormationContainer>
      </ScFormationDynamic>
    </>
  );
}
