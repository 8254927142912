import { Link } from "react-router-dom";
import styled from "styled-components";
import { Color, Font, Points, Radius } from "../../style_config/VarStyle";
import { Container, Section } from "../../style_config/UseStyle";

export const LinkingSection = styled(Section)`
  position: relative;
  padding-bottom: 4rem;
  padding-top: 1rem;

  &::after {
    content: "";
    background: ${Color.bg_color};
    position: absolute;
    height: 100%;
    width: 500%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(3deg);
    z-index: -1;
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding-top: 0;

    &::after {
      transform: translate(-50%, -50%) rotate(10deg);
    }
  }
`;

export const FullContainer = styled(Container)`
  padding-top: 1rem;
  display: grid;
  row-gap: 1rem;
  grid-template-rows: min-content 1fr;
`;

export const FullBlock = styled.div`
  background: linear-gradient(
    45deg,
    ${Color.first_color_alt},
    hsl(42, 100%, 50%) 100%
  );
  padding-bottom: 5rem;
`;

export const LinkingTitle = styled.h1`
  color: ${({ inverse }) =>
    inverse === "gradient"
      ? "transparent"
      : inverse
      ? Color.text_color
      : "#fff"};
  font-weight: ${Font.weight.font_semi_bold};
  font-size: ${Font.size.h1_font_size};
  text-transform: uppercase;
  text-align: center;
  padding-bottom: ${({ mb }) => (mb > 0 ? mb + "rem" : "0")};
  background: ${({ inverse }) =>
    inverse === "gradient"
      ? `linear-gradient(to right,${Color.first_color},hsl(112, 100%, 42%))`
      : ""};
  background-clip: ${({ inverse }) => (inverse === "gradient" ? "text" : "")};
  -webkit-background-clip: ${({ inverse }) =>
    inverse === "gradient" ? "text" : ""};
  -webkit-text-fill-color: ${({ inverse }) =>
    inverse === "gradient" ? "transparent" : ""};
`;

export const LinkingSubTitle = styled.h3`
  color: ${Color.text_color_light};
  text-transform: capitalize;
  text-align: center;
  font-size: ${Font.size.small_font_size};
  padding-bottom: ${({ mb }) => (mb > 0 ? mb + "rem" : "0")};
`;

export const FullData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: center;
  gap: 1rem;
`;

export const CheckedLink = styled.div`
  width: 12px;
  height: 12px;
  background: none;
  border-radius: 100%;
  border: 2px solid
    ${({ inverse }) =>
      inverse === "true" ? Color.text_color : Color.container_color};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 3px;
  transition: none;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    width: 0%;
    height: 0%;
    background: ${({ inverse }) =>
      inverse === "true" ? Color.text_color : Color.container_color};
    transition: all 0.1s linear;
  }
`;

export const LinkingData = styled(Link)`
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: min-content 1fr;
  align-items: center;
  color: ${({ inverse }) =>
    inverse === "true" ? Color.text_color : Color.container_color};

  span {
    text-transform: capitalize;
    font-size: ${({ fs }) => (fs ? fs : "")};
  }

  &:hover ${CheckedLink} {
    border: 0px;

    &::before {
      height: 100%;
      width: 100%;
    }
  }
`;

export const DataContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  justify-content: center;
  column-gap: 4rem;

  @media screen and (max-width: ${Points.smallScreen}) {
    grid-template-columns: minmax(210px, 340px);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 2rem;
  }
`;

export const DataBox = styled.div`
  background-color: ${Color.container_color};
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.21);
  border-radius: ${Radius};
  padding: 2rem 1rem;
  position: relative;
  top: -3.5rem;
`;

export const Data = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, max-content);
  gap: 1rem;
  justify-content: center;
  padding-top: 2rem;
`;
