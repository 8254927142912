import React, { useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
// import Actuality from "../../components/home/Actuality";
import Hero from "../../../components/home/Hero";
import HomePub from "../../../components/home/HomePub";
import Linking from "../../../components/home/Linking";
import { useEventListener, useNavColor } from "../../../hooks";
import { Main } from "./Smain";
import { Anchor } from "../../../style_config/UseStyle";

export default function Home({ setnav }) {
  useNavColor(false, setnav);
  const [show, setShow] = useState(false);

  useEventListener("scroll", (_) => {
    if (window.scrollY > 500) {
      setShow(true);
    } else {
      setShow(false);
    }
  });

  return (
    <Main>
      <Hero />
      <Linking />
      {/* <Actuality /> */}
      <HomePub />
      <Anchor
        className={show ? "show" : ""}
        to="home"
        smooth={true}
        duration={500}
      >
        <MdKeyboardArrowUp />
      </Anchor>
    </Main>
  );
}
