import React from "react";
import { Input, Label } from "../../style_config/UseStyle";

export default function FilterCat({ data, oneSelect, isChecked }) {
  const dataMap = data.reduce((acc, curr) => {
    return acc.includes(curr.categories) ? [...acc] : [...acc, curr.categories];
  }, []);
  return (
    <>
      {dataMap.map((item, index) => (
        <Label
          key={index}
          htmlFor="filterRadio"
          ml={0}
          fw={1}
          grid="true"
          data-value={item}
          onClick={oneSelect}
        >
          <Input
            type="radio"
            name="filter"
            data-value={item}
            onClick={oneSelect}
            checked={isChecked(item)}
            readOnly
          />
          <div></div>
          {/* Label text */}
          {item}
        </Label>
      ))}
    </>
  );
}
