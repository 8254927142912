import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { UlList } from "../formation/Sformation";
import { useNavColor } from "../../../hooks";
import DataClient from "../../../data/DataClient";
import { Color, Font } from "../../../style_config/VarStyle";
import { Hr } from "../../../style_config/UseStyle";
import S from "./SdynamicArticle";

const DynamicArticle = ({ setnav }) => {
  useNavColor(true, setnav);
  const { articleFixture } = DataClient;
  const { id } = useParams();
  const item = articleFixture.data.find((el) => el.path.split("/")[2] === id);
  return (
    <>
      <Helmet>
        <title>{item.auteur} - Articles - Laboratoire URGPGE</title>
        <meta
          name="keywords"
          content={
            "articles,articles publiées," +
            item.auteur +
            "," +
            item.categories +
            "," +
            item.title +
            "," +
            item.keywords.join(",")
          }
        />
        <meta
          name="description"
          content={item.abstract.slice(0, 150) + " ..."}
        />
      </Helmet>
      <S.DynamicArticle>
        <S.Container>
          <S.GridTop>
            <S.Title
              size={Font.size.h1_font_size}
              txt_color={Color.first_color}
              id="title"
              dangerouslySetInnerHTML={{ __html: item.title }}
            ></S.Title>
            <S.Title
              size={Font.size.h2_font_size}
              txt_color={Color.title_color}
            >
              {item.auteur + " - " + item.categories}
            </S.Title>
            <S.Title
              size={Font.size.normal_font_size}
              txt_color={Color.text_color}
            >
              Publication{" "}
              {new Date(item.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </S.Title>
          </S.GridTop>
          <S.GridLeft>
            <S.Title
              size={Font.size.normal_font_size}
              txt_color={Color.title_color}
            >
              Abstract :
            </S.Title>
            <S.DataText
              txt_align="justify"
              ml={1}
              dangerouslySetInnerHTML={{ __html: item.abstract }}
            ></S.DataText>
            <S.Title
              size={Font.size.normal_font_size}
              txt_color={Color.title_color}
            >
              Author and co-author:
            </S.Title>
            <S.DataText fl={true} ml={1}>
              {item.auteur + ", " + item.subTitle}
            </S.DataText>
          </S.GridLeft>
          <S.GridRight>
            <S.Title
              size={Font.size.normal_font_size}
              txt_align="center"
              txt_color={Color.title_color}
            >
              Keywords
            </S.Title>
            <Hr mb={1} center={true} />
            <UlList>
              {item.keywords.map((data, i) => (
                <li key={i}>{data}</li>
              ))}
            </UlList>

            <S.Title
              size={Font.size.normal_font_size}
              txt_align="center"
              txt_color={Color.title_color}
            >
              Journal
            </S.Title>
            <Hr mb={1} center={true} />
            <S.OpenLink>
              <span>{item.journal}</span>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <S.Icon mt={1} />
              </a>
            </S.OpenLink>
          </S.GridRight>
        </S.Container>
      </S.DynamicArticle>
    </>
  );
};

export default DynamicArticle;
