import React from "react";
import { useToogle } from "../../hooks";
import {
  AnnuaireEmail,
  AnnuaireName,
  AnnuaireProfile,
  AnnuaireSpeciality,
  ArrowSee,
  BoxSee,
  EmailLink,
  LabelP,
} from "../../pages/homepage/contact/Sannuaire";
import { ShowMore } from "./ShowMore";

export const BoxAnnuaire = ({ data }) => {
  const [show, toogleShow] = useToogle(false);
  return (
    <>
      <BoxSee onClick={toogleShow}>
        <AnnuaireProfile>
          <img src={data.img} alt="profile" />
        </AnnuaireProfile>
        <AnnuaireName>{data.name}</AnnuaireName>
        <AnnuaireSpeciality>
          <LabelP>Spécialité: </LabelP>
          {data.speciality}
        </AnnuaireSpeciality>
        <AnnuaireEmail>
          <LabelP>E-mail: </LabelP>
          <EmailLink href={`mailto:${data.email}`}>{data.email}</EmailLink>
        </AnnuaireEmail>
        <ArrowSee />
      </BoxSee>
      {show && <ShowMore data={data} toogle={toogleShow} />}
    </>
  );
};

export default BoxAnnuaire;
