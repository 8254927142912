import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { SlArrowDown } from "react-icons/sl";
import { useEventListener } from "../../hooks";
import Data from "../../data/DataClient";
import {
  Hero,
  MobileIcon,
  Nav,
  NavItem,
  NavlinkStyle,
  NavLogo,
  NavMenu,
  StyledBottom,
  Dropdownlink,
  DropdownName,
  DropdownStyle,
} from "./Snavbar";

const { navbar } = Data;

const Dropdown = ({ link, scroll, nav }) => {
  return (
    <>
      <DropdownName scroll={scroll + ""} colorlink={nav + ""}>
        {link.name}
        <SlArrowDown />
      </DropdownName>
      <DropdownStyle>
        {link.drop.map((dropLink, key) => (
          <Dropdownlink key={key} to={dropLink.path}>
            {dropLink.name}
          </Dropdownlink>
        ))}
      </DropdownStyle>
    </>
  );
};

const initial = {
  width: 0,
  left: -10,
};

const Navbar = ({ nav, annim, setAnnim, setShow }) => {
  const [scroll, setScroll] = useState(false);
  const [state, setState] = useState(initial);

  const handleBottom = function (e) {
    setState({
      width: e.target.offsetWidth,
      left: e.target.offsetLeft,
    });
  };

  useEventListener("scroll", (_) => {
    if (window.scrollY > 64) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  });

  return (
    <>
      <Hero scroll={scroll + ""} colorlink={nav + ""}>
        <Nav>
          <NavLogo to={navbar.logo.path}>
            <img src={navbar.logo.img} alt="Logo-URGPGE" />
          </NavLogo>
          <MobileIcon
            annim={annim}
            onClick={(e) => {
              setShow();
              setAnnim();
            }}
          >
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {navbar.menu.map((link, index) => (
              <NavItem
                key={index}
                onMouseEnter={handleBottom}
                onMouseLeave={(_) => setState(initial)}
              >
                {link.down ? (
                  <Dropdown link={link} scroll={scroll + ""} nav={nav} />
                ) : (
                  <NavlinkStyle
                    scroll={scroll + ""}
                    colorlink={nav + ""}
                    to={link.path}
                  >
                    {link.name}
                  </NavlinkStyle>
                )}
              </NavItem>
            ))}
            <StyledBottom width={state.width} left={state.left} />
          </NavMenu>
        </Nav>
      </Hero>
    </>
  );
};

export default Navbar;
