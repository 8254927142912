import { useState } from "react";
import { Link } from "react-router-dom";
import DataClient  from "../../data/DataClient";
import { useEventListener, useSetIntervale, useToogle } from "../../hooks";
import { Button } from "../../style_config/UseStyle";
import {
  ArrowForward,
  ArrowRight,
  HeroContainer,
  HeroH1,
  HeroP,
  HeroSection,
  HeroSlide,
  HeroSlides,
} from "./Shero";

const Hero = () => {
  const { hero } = DataClient;
  const [transition, setTransition] = useState(false);
  const [index, setIndex] = useState(0);
  const [once, setOnce] = useState(true);
  const [hover, setHover] = useToogle(false);

  useEventListener(
    "transitionend",
    () => {
      const slides = document.querySelectorAll(".slide");
      if (index === slides.length - 1) {
        setIndex(1);
        setTransition(true);
      }
    },
    document.querySelector("#slides")
  );

  useSetIntervale(() => {
    setIndex(index + 1);
    setTransition(false);
    const slides = document.querySelectorAll(".slide");
    const slide = document.querySelector("#slides");

    if (once) {
      slide.append(slides[0].cloneNode(true));
      slide.prepend(slides[slides.length - 1].cloneNode(true));
      setIndex(2);
      setOnce(false);
    }
  }, 4000);

  return (
    <>
      <HeroSection id="home">
        <HeroSlides index={index} transition={transition} id="slides">
          {hero.SlideData.map((data, key) => (
            <HeroSlide key={data + key} className="slide">
              <img src={data.img} alt="URGPGE Laboratoire" />
            </HeroSlide>
          ))}
        </HeroSlides>
        <HeroContainer>
          <HeroH1>{hero.content.title}</HeroH1>
          <HeroP>{hero.content.text}</HeroP>
          <Button
            as={Link}
            grad="true"
            to={hero.content.btn.path}
            onMouseEnter={setHover}
            onMouseLeave={setHover}
          >
            {hero.content.btn.text}
            {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroContainer>
      </HeroSection>
    </>
  );
};

export default Hero;
