import { Link } from "react-router-dom";
import styled from "styled-components";
import { Color, Font, Points, Radius } from "../../style_config/VarStyle";
import {
  Container,
  Section,
  SectionTitleCss,
} from "../../style_config/UseStyle";

export const PubSection = styled(Section)`
  position: relative;
  /* padding-bottom: 6rem; */
  padding-top: 6rem;

  &::after {
    content: "";
    background: linear-gradient(
      to right,
      ${Color.first_color} 50%,
      hsl(112, 100%, 42%)
    );
    position: absolute;
    height: 100%;
    width: 500%;
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translate(-65%, -50%) rotate(3deg);
    z-index: -1;
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding-top: 4rem;
    margin-top: 1rem;
    &::after {
      transform: translate(-57%, -50%) rotate(10deg);
    }
  }
`;

export const PubContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: minmax(290px, 1fr);
  row-gap: 1.5rem;
  justify-content: center;
`;

export const PubTitle = styled.h1`
  ${SectionTitleCss}
  color: ${Color.container_color};
  font-weight: ${Font.weight.font_semi_bold};
  text-transform: uppercase;
  text-align: center;
`;

export const BoxPub = styled(Link)`
  background-color: ${Color.container_color};
  box-shadow: 0 3.5px 8px rgba(0, 0, 0, 0.21);
  border-radius: ${Radius};
  row-gap: 1rem;
  margin: 0 1rem;
  padding: 1rem;
  cursor: pointer;
  display: grid;

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0.5rem;
  }
`;

export const BoxPubTitle = styled.h2`
  color: ${Color.first_color_alt};
  font-size: ${Font.size.h2_font_size};
  font-weight: ${Font.weight.font_semi_bold};
`;

export const BoxPubSubTitle = styled.h3`
  color: ${Color.text_color_light};
  font-size: ${Font.size.normal_font_size};
  font-weight: ${Font.weight.font_regular};
  font-style: italic;
`;

export const BoxPubCategories = styled.h4`
  color: ${Color.text_color_light};
  font-size: ${Font.size.normal_font_size};
  font-weight: ${Font.weight.font_semi_bold};
`;
export const BoxPubYears = styled.h5`
  color: ${Color.title_color};
  font-size: ${Font.size.normal_font_size};
  font-weight: ${Font.weight.font_semi_bold};
`;
