import React from "react";
import { Helmet } from "react-helmet";
import Pagination from "../../../components/pagination/pagination";
import FilterCat from "../../../components/helpers/FilterCat";
import FilterYears from "../../../components/helpers/FilterYears";
import Data from "../../../data/DataClient";
import { useHandlerRadio, usePagination, useNavColor } from "../../../hooks";
import { Color, Font } from "../../../style_config/VarStyle";
import { Container, Hr } from "../../../style_config/UseStyle";
import S from "./Sarticles";

export default function Articles({ setnav }) {
  useNavColor(true, setnav);
  const { articleFixture } = Data;
  const [isChecked, oneSelect, checked] = useHandlerRadio("0");
  const [isCheckedYears, oneSelectYears, checkedYears] = useHandlerRadio("0");

  const filtre = (item) => {
    let temp = item;
    if (checked !== "0" && checkedYears !== "0") {
      temp =
        checked === temp.categories &&
        checkedYears === temp.date.getFullYear().toString()
          ? temp
          : null;
    } else if (checked !== "0" && checkedYears === "0") {
      temp = checked === temp.categories ? temp : null;
    } else if (checked === "0" && checkedYears !== "0") {
      temp = checkedYears === temp.date.getFullYear().toString() ? temp : null;
    }
    return temp;
  };

  const [data, page] = usePagination(
    articleFixture.data
      .filter(filtre)
      .sort((a, b) => b.date.getTime() - a.date.getTime()),
    5
  );

  return (
    <>
      <Helmet>
        <title>Articles - Laboratoire URGPGE</title>
        <meta name="keywords" content="articles,articles publiées" />
        <meta name="description" content="La journal des articles publiées." />
      </Helmet>
      <S.Articles>
        <Container>
          <S.Title shadow_box_color={true} pad={1} bgc={Color.container_color}>
            Journal d'articles
          </S.Title>
          <S.GridLayout>
            <S.GridLeft>
              {data && data.length !== 0 ? (
                data.map((obj, i) => {
                  const cat = obj.categories === "Eau et environnement";
                  return (
                    <S.Data cat={cat.toString()} key={i} to={obj.path}>
                      <S.Title
                        txt_color="gradient"
                        cat={cat.toString()}
                        lh={true}
                        mb={0.5}
                        size={Font.size.h2_font_size}
                        dangerouslySetInnerHTML={{ __html: obj.title }}
                      ></S.Title>
                      <S.Badge cat={cat.toString()}>{obj.categories}</S.Badge>
                      <S.Text fw={Font.weight.font_semi_bold}>
                        Par : {obj.auteur}
                      </S.Text>
                      <S.Text>
                        Co-auteur : {obj.subTitle}
                        <br />
                        Publication :
                        {" " +
                          obj.date.toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </S.Text>
                    </S.Data>
                  );
                })
              ) : (
                <S.Data as="div">Aucun résultat trouvée</S.Data>
              )}
            </S.GridLeft>
            <S.GridRight>
              {/* First Content */}
              <S.Content>
                <S.CloseIcon
                  t={0.7}
                  r={0.5}
                  data-value="0"
                  disp={checked !== "0" ? "true" : "false"}
                  opacity={checked !== "0" ? 1 : 0}
                  onClick={oneSelect}
                />
                <S.Title lh={true} mb={0.5} size={Font.size.normal_font_size}>
                  Filtré par catégories
                </S.Title>
                <Hr mb={1} center={true} w={100} />
                <FilterCat
                  data={articleFixture.data}
                  isChecked={isChecked}
                  oneSelect={oneSelect}
                />
              </S.Content>

              {/* Second Content */}
              <S.Content>
                <S.CloseIcon
                  data-value="0"
                  t={0.7}
                  r={0.5}
                  disp={checkedYears !== "0" ? "true" : "false"}
                  opacity={checkedYears !== "0" ? 1 : 0}
                  onClick={oneSelectYears}
                />
                <S.Title lh={true} mb={0.5} size={Font.size.normal_font_size}>
                  Filtré par années
                </S.Title>
                <Hr mb={1} center={true} w={100} />
                <FilterYears
                  data={articleFixture.data}
                  isChecked={isCheckedYears}
                  oneSelect={oneSelectYears}
                />
              </S.Content>
            </S.GridRight>
          </S.GridLayout>
          <Pagination pageInfo={page} />
        </Container>
      </S.Articles>
    </>
  );
}
