import styled, { keyframes } from "styled-components";
import { Color, Font } from "../../style_config/VarStyle";
import { Container } from "../../style_config/UseStyle";

const animGrad = keyframes`
0%{
  background-position: 0 0;
}
25%{
  background-position: 100% 0;
}
50%{
  background-position: 100% 100%;
}
75%{
  background-position: 0 100%;
}
100%{
  background-position: 0 0;
}
`;

export const SCNotFound = styled.section`
  height: 100vh;
  background: ${Color.container_color};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SCNContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SCNText = styled.div`
  font-size: ${({ fs }) => (fs ? fs : "")};
  font-weight: ${({ fw }) => (fw ? fw : "")};
  word-spacing: ${({ ws }) => (ws ? ws : "")};
  text-transform: ${({ cas }) =>
    cas === 1
      ? "uppercase"
      : cas === 2
      ? "lowercase"
      : cas === 3
      ? "capitalize"
      : ""};
  text-align: ${({ talign }) => (talign ? talign : "")};
  background: ${({ bg }) => (bg ? bg : "")};
  background-clip: ${({ grad }) => (grad ? "text" : "")};
  -webkit-background-clip: ${({ grad }) => (grad ? "text" : "")};
  color: ${({ grad, tcolor }) => (grad ? "transparent" : tcolor ? tcolor : "")};
  height: ${({ h }) => (h ? h : "")};
  width: ${({ w }) => (w ? w : "")};
  display: ${({ dflex }) => (dflex ? "flex" : "")};
  align-items: ${({ alignflex }) => (alignflex ? alignflex : "")};
  justify-content: ${({ justflex }) => (justflex ? justflex : "")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
`;

export const SCNErrorText = styled.div`
  font-size: calc(${Font.size.big_font_size} + 10rem);
  font-weight: ${({ fs }) => (fs ? fs : "")};
  text-align: center;
  position: relative;
  color: transparent;
  line-height: normal;
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};

  &::after {
    content: "404";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: repeating-linear-gradient(
      45deg,
      ${Color.first_color},
      hsl(112, 100%, 42%),
      ${Color.blue_color},
      hsl(42, 100%, 50%),
      ${Color.first_color_alt}
    );
    background-size: 400%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    animation: ${animGrad} 3s linear infinite;
    text-shadow: 0 3px 5px rgba(240, 240, 240, 0.5);
  }
`;
