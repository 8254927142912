import styled from "styled-components";
import { Color, Font, Points, Radius } from "../../style_config/VarStyle";

export const PaginationBlock = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  gap: 0.3rem;
  justify-content: center;
  @media screen and (max-width: ${Points.smallScreen}) {
    gap: 0.1rem;
  }
`;

export const PaginationElement = styled.div`
  padding: 5px 10px;
  font-size: ${Font.size.h4_font_size};
  font-weight: ${Font.weight.font_medium};
  border-top-left-radius: ${({ radTopLeft }) =>
    radTopLeft ? Radius : ".3rem"};
  border-top-right-radius: ${({ radTopRight }) =>
    radTopRight ? Radius : ".3rem"};
  border-bottom-left-radius: ${({ radBottomLeft }) =>
    radBottomLeft ? Radius : ".3rem"};
  border-bottom-right-radius: ${({ radBottomRight }) =>
    radBottomRight ? Radius : ".3rem"};
  user-select: none;
  cursor: ${({ initial }) => (initial ? "default" : "pointer")};

  ${({ notHover }) =>
    notHover
      ? null
      : `&:hover {
    background-color: ${Color.first_color};
    color: ${Color.container_color};

    svg{
      color: ${Color.container_color};
    }
  }`}

  &.active {
    background-color: ${Color.first_color_alt};
    color: ${Color.container_color};
  }

  & svg {
    color: ${Color.title_color};
    width: 100%;
    height: 100%;
    transition: color 0.1s linear;

    &:hover {
      color: ${Color.container_color};
    }
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    font-size: ${Font.size.normal_font_size};
    padding: 5px 10px;
  }
`;
