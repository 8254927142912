import styled from "styled-components";
import { MdArrowForward } from "react-icons/md";
import { Container, Hr, Section } from "../../../style_config/UseStyle";
import {
  Color,
  Font,
  Header_height,
  Points,
  Radius,
  Z_index,
} from "../../../style_config/VarStyle";

export const AnnuaireStyle = styled(Section)`
  margin-top: ${Header_height};
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 100vh;
  max-height: fit-content;
  height: fit-content;
  background: ${Color.bg_color};
`;

export const AnnuaireContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 0 2.5rem;
  }
`;

// ------------ Box Annuaire ------------

export const AnnuaireProfile = styled.div`
  grid-area: img;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    border-radius: ${Radius};
    object-fit: cover;
    cursor: pointer;
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    img {
      width: 50%;
    }
  }
`;

export const AnnuaireName = styled.h3`
  grid-area: ${({ area }) => (area ? area : "sct1")};
  cursor: pointer;
`;

export const AnnuaireSpeciality = styled.p`
  grid-area: ${({ area }) => (area ? area : "sct2")};
  cursor: pointer;
`;

export const LabelP = styled.span`
  font-weight: ${Font.weight.font_semi_bold};
  cursor: pointer;
`;

export const AnnuaireEmail = styled.p`
  grid-area: ${({ area }) => (area ? area : "sct3")};
  cursor: pointer;
`;

export const EmailLink = styled.a`
  cursor: pointer;
`;

export const ArrowSee = styled(MdArrowForward)`
  font-size: calc(${Font.size.big_font_size} + 0.2rem);
  color: transparent;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;

  @media screen and (max-width: ${Points.smallScreen}) {
    top: 20%;
    transform: none;
  }
`;

export const BoxSee = styled.div`
  background-color: ${Color.container_color};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.21);
  border-radius: ${Radius};
  padding: 2rem;
  cursor: pointer;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-areas: "img sct1" "img sct2" "img sct3";
  gap: 2rem;
  position: relative;

  &:hover {
    background-color: ${Color.blue_color};

    ${LabelP},${ArrowSee},${AnnuaireSpeciality},${AnnuaireEmail} {
      color: ${Color.title_color};
    }
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    grid-template-columns: 1fr;
    grid-template-areas: "img" "sct1" "sct2" "sct3";
  }
`;

export const AnnuaireTitle = styled.h1`
  font-size: ${({ size }) => (size ? size : Font.size.big_font_size)};
  color: ${({ txt_color }) =>
    txt_color === "gradient"
      ? "transparent"
      : txt_color
      ? txt_color
      : Color.title_color};

  ${({ txt_color }) =>
    txt_color === "gradient"
      ? `background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;`
      : ""}
  background-image: ${({ cat, txt_color }) =>
    txt_color === "gradient"
      ? cat
        ? "linear-gradient(45deg," + Color.first_color + ",hsl(112,100%,42%))"
        : "linear-gradient(45deg," +
          Color.first_color_alt +
          ", hsl(42,100%,50%))"
      : ""};
  text-transform: ${({ txt_case }) =>
    txt_case === 1
      ? "lowercase"
      : txt_case === 2
      ? "capitalize"
      : txt_case === 3
      ? "uppercase"
      : "none"};
  line-height: ${({ lh }) => (lh ? "1.5rem" : "2.5rem")};
  padding: ${({ pad }) => (pad ? pad + "rem" : "none")};
  text-align: ${({ txt_align }) => (txt_align ? txt_align : "")};
  margin-bottom: ${({ mb }) => (mb ? mb + "rem" : "0rem")};
  margin-top: ${({ mt }) => (mt ? mt + "rem" : "0rem")};
  margin-left: ${({ ml }) => (ml ? ml + "rem" : "0rem")};
  margin-right: ${({ mr }) => (mr ? mr + "rem" : "0rem")};
  background-color: ${({ bgc }) => (bgc ? bgc : "none")};
  box-shadow: 0 2px 3px
    ${({ shadow_box_color }) =>
      shadow_box_color ? "rgba(0, 0, 0, 0.1)" : "transparent"};
`;

// ----------- Show Style----------

export const ShowBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${Z_index.z_modal};
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: ${Font.size.h3_font_size};
    cursor: pointer;
    color: #fff;
  }
`;

export const ShowContainer = styled(Container)`
  background-color: #fff;
  height: 90%;
  width: 100%;
  border-radius: ${Radius};
  padding: 2rem;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.21);
  position: relative;

  @media screen and (max-width: ${Points.mobile}) {
    padding: 1rem;
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 1rem 0.4rem;
  }
`;

export const ShowBox = styled.div`
  width: 100%;
  height: 100%;
  max-height: 95%;
`;

export const Onglet = styled.div`
  display: flex;
  justify-content: center;
`;

export const OngletElement = styled.button`
  margin-bottom: -1px;
  padding: 0.5rem 1rem;
  border-top-left-radius: ${Radius};
  border-top-right-radius: ${Radius};
  background: 0 0;
  border: 1px solid transparent;
  color: ${Color.text_color_light};
  cursor: pointer;

  &:hover {
    border: 1px solid ${Color.text_color_light};
  }

  &.active {
    color: ${Color.title_color};
    border-bottom: 1px solid ${Color.container_color} !important;
    border: 1px solid ${Color.text_color_light};
    background-color: ${Color.container_color};
  }
`;

export const Separate = styled(Hr)`
  width: 100%;
  background-color: ${Color.text_color_light};
`;

// ================== Contenu 1 ===============

export const Content = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  margin-top: 2px;
  overflow-y: auto;
  overflow-x: hidden;

  &.active {
    display: block;
  }
`;

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-areas:
    "img sct1"
    "img sct2"
    "img sct3"
    "sct4 sct4";
  gap: 1rem;
  background-color: ${Color.body_color};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.21);
  border-radius: ${Radius};
  padding: 1rem;
  min-width: min-content;

  @media screen and (max-width: ${Points.smallScreen}) {
    grid-template-columns: 1fr;
    grid-template-areas: "img" "sct1" "sct2" "sct3" "sct4";
  }
`;
export const AnnuaireStatus = styled.p`
  grid-area: ${({ area }) => (area ? area : "sct2")};
`;

export const AnnuaireTel = styled.p`
  grid-area: ${({ area }) => (area ? area : "sct5")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    position: static;
    color: ${Color.blue_color};
  }
`;

export const AnnuaireEmail2 = styled.p`
  grid-area: ${({ area }) => (area ? area : "sct5")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    position: static;
    color: ${Color.blue_color};
  }
`;

export const ContentEmailTel = styled.div`
  grid-area: ${({ area }) => (area ? area : "sct4")};
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: ${Points.smallScreen}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

// ------------ Projet en cours ----------------
export const ProjectAndPub = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${Color.text_color};
  ${({ flex }) => `display: flex; flex-direction: column; gap: 1rem;`}

  & > h3 {
    text-transform: uppercase;
  }
`;

export const ProjectAndPubData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
`;

// =============== Contenu 2 =================

export const BoxContact = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${Points.mobile}) {
    padding: 1rem;
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    padding: 1rem 0.5rem;
  }

  @media screen and (max-width: ${Points.extraSmallScreen}) {
    padding: 1rem 0;
  }
`;

export const ContactTitle = styled.h2`
  text-align: center;
`;

export const FormContact = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: ${Points.mobile}) {
    width: 96%;
  }

  @media screen and (max-width: ${Points.smallScreen}) {
    width: 100%;
    padding: 1rem 0;
  }
`;

export const TextSend = styled.div`
  margin-bottom: 0.5rem;
  & > span {
    text-transform: uppercase;
    font-weight: ${Font.weight.font_semi_bold};
    margin-left: 1rem;
  }

  p {
    color: ${Color.blue_color};
    display: inline-block;
    margin-left: 1rem;
  }
`;
