import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Button, Input, Label } from "../../style_config/UseStyle";
import { Color, Font } from "../../style_config/VarStyle";
import { useDispatch, useSelector } from "react-redux";
import Spiner from "../../components/spiner";
import { useEventListener } from "../../hooks";
import { login } from "../../store/auth.slice";
import DataClient from "../../data/DataClient";
import S from "./Styles";

const {
  auth: { signIn: clientData },
} = DataClient;

const initialState = (dataArray) => {
  let res = {};
  dataArray.map((obj) => {
    if (obj.required) {
      res[obj.name] = "";
    }
    return null;
  });
  return res;
};
const selectWatchCanSubmitData = (dataArray, state) => {
  return dataArray
    .reduce((acc, cur) => {
      return cur.required ? [...acc, state[cur.name]] : [...acc];
    }, [])
    .every(Boolean);
};

export default function SignIn({ setSignIn }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [showPwd, setShowPwd] = useState({});
  const [state, setState] = useState(initialState(clientData.form));
  const canSubmit =
    !selectWatchCanSubmitData(clientData.form, state) && !isLoading;

  const inputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let sendData = {};
    Object.entries(state).map(([key, value]) => {
      switch (key) {
        default:
          sendData[key] = value.toLowerCase();
          return null;
      }
    });
    dispatch(login(sendData))
      .unwrap()
      .then(({ message }) => {
        toast.success(message);
        navigate("/dashboard");
      })
      .catch(({ message }) => {
        toast.error(message);
      });
  };
  useEventListener(
    "keydown",
    (e) => {
      if (e.code === "Enter" && !canSubmit) {
        handleSubmit(e);
      }
    },
    document
  );
  return (
    <S.Data>
      <S.Title
        grad="true"
        bg={`linear-gradient(45deg,${Color.first_color},hsl(112, 100%, 42%))`}
        talign="center"
        fw={Font.weight.font_semi_bold}
      >
        {clientData.title}
      </S.Title>
      {clientData.form.map((item, i) => {
        switch (item.type) {
          case "password":
            return (
              <div key={i}>
                <Label mt={1} mb={0.5} fw={1} htmlFor={item.name}>
                  {item.label} {item.required && <span>*</span>}
                </Label>
                <S.Infield>
                  <Input
                    type={showPwd[item.name] ? "text" : "password"}
                    placeholder={item.placeholder}
                    name={item.name}
                    pad="0.5rem 1rem"
                    w="100%"
                    onChange={inputChange}
                    value={state[item.name] ? state[item.name] : ""}
                  />
                  {showPwd[item.name] ? (
                    <AiFillEye
                      onClick={(e) =>
                        setShowPwd({
                          ...showPwd,
                          [item.name]: !showPwd[item.name],
                        })
                      }
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={(e) =>
                        setShowPwd({
                          ...showPwd,
                          [item.name]: !showPwd[item.name],
                        })
                      }
                    />
                  )}
                </S.Infield>
              </div>
            );

          default:
            return (
              <div key={i}>
                <Label mt={1} mb={0.5} fw={1} htmlFor={item.name}>
                  {item.label} {item.required && <span>*</span>}
                </Label>
                <Input
                  type={item.type}
                  placeholder={item.placeholder}
                  name={item.name}
                  pad="0.5rem 1rem"
                  w="100%"
                  onChange={inputChange}
                  value={state[item.name] ? state[item.name] : ""}
                />
              </div>
            );
        }
      })}

      {isLoading ? (
        <Spiner style={{ margin: "1.02rem auto 0rem auto" }} size={"2.49rem"} />
      ) : (
        <Button
          type="submit"
          mt={1}
          center="true"
          grad="true"
          onClick={handleSubmit}
          disabled={canSubmit}
        >
          {clientData.submit}
        </Button>
      )}
      <S.Text>
        {clientData.link.text}{" "}
        <S.Link
          to={clientData.link.path}
          grad="true"
          bg={`linear-gradient(45deg,${Color.first_color},hsl(112, 100%, 42%))`}
          onClick={(e) => setSignIn(true)}
        >
          {clientData.link.a}
        </S.Link>
      </S.Text>
    </S.Data>
  );
}
