import { TiArrowSortedDown } from "react-icons/ti";
import styled from "styled-components";
import { Color, Font, Radius } from "../../style_config/VarStyle";

export const ScAcordion = styled.div`
  padding: 0.2rem;
  border-radius: ${Radius};
  overflow: hidden;
  height: fit-content;
  background: ${({ bgcolor }) =>
    bgcolor === "true"
      ? "rgb(40, 40, 40)"
      : `linear-gradient(45deg,${Color.first_color},hsl(112, 100%, 42%))`};

  &:hover {
    background-color: rgb(40, 40, 40);
  }
`;

export const ScAcordionHeader = styled.div`
  color: ${Color.container_color};
  font-weight: ${Font.weight.font_semi_bold};
  font-size: ${Font.size.h2_font_size};
  padding: 0.3rem 1rem;
  border-radius: ${Radius} ${Radius} 0 0;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  cursor: pointer;
  background-color: ${({ bgcolor }) =>
    bgcolor === "true" ? "rgb(40, 40, 40)" : ""};
  transition: background-image 0.3s linear;

  &:hover {
    background: linear-gradient(
      45deg,
      ${Color.first_color},
      hsl(112, 100%, 42%)
    );
  }
`;

export const ScIconArrow = styled(TiArrowSortedDown)`
  transform: rotate(
    ${({ icon_up }) => (icon_up === "true" ? "-180deg" : "0deg")}
  );
`;

export const ScAcordionData = styled.div`
  background-color: rgba(245, 245, 245, 1);
  border-radius: 0 0 ${Radius} ${Radius};
  padding-left: ${({ pl }) => (pl ? pl + "rem" : "1rem")};
  padding-right: ${({ pr }) => (pr ? pr + "rem" : "1rem")};
  visibility: ${({ down }) => (down === "true" ? "visible" : "hidden")};
  padding-top: ${({ down }) => (down === "true" ? "0.3rem" : "none")};
  padding-bottom: ${({ down, pb }) =>
    pb && down === "true" ? pb + "rem" : down === "true" ? "0.3rem" : "none"};
  height: ${({ down }) => (down === "true" ? "none" : "0px")};
  transition: all 0.3s ease-in-out;
`;
