// hue color
export const Hue = 165;
export const Header_height = "4rem";
export const Radius = ".5rem";

export const Color = {
  first_color: `hsl(${Hue},100%,37%)`,
  first_color_alt: `hsl(14, 92%, 52%)`,
  first_color_lighter: "hsla(165, 100%, 41%, 0.6)",
  title_color: `hsl(${Hue},2%, 18%)`,
  text_color: `hsl(0, 8%, 46%)`,
  text_color_light: `hsl(${Hue},8%, 65%)`,
  input_color: `rgba(246, 246, 246, 65%)`,
  body_color: `hsl(0,0%, 93%)`,
  container_color: "#fff",
  blue_color: "#21c8ff",
  bg_color: "linear-gradient(to right, #d3cce3, #e9e4f0)",
};

export const Font = {
  body_font: "'Roboto',sans-serif",
  size: {
    // .5rem=4px,1rem=16px,1.5rem=24px ...
    big_font_size: "2rem",
    h1_font_size: "1.5rem",
    h2_font_size: "1.25rem",
    h3_font_size: "1.125rem",
    normal_font_size: ".9rem",
    small_font_size: ".8rem",
    smaller_font_size: ".75rem",
  },
  weight: {
    font_regular: "400",
    font_medium: "500",
    font_semi_bold: "600",
  },
};

export const Margin = {
  // .5rem=4px,1rem=16px,1.5rem=24px ...
  mb_0_25: ".25rem",
  mb_0_5: ".5rem",
  mb_0_75: ".75rem",
  mb_1: "1rem",
  mb_1_5: "1.5rem",
  mb_2: "2rem",
  mb_2_5: "2.5rem",
  mb_3: "3rem",
};

export const Z_index = {
  z_tooltip: 10,
  z_fixed: 100,
  z_modal: 1000,
};

export const Points = {
  desktop: "1200px",
  tablet: "992px",
  mobile: "768px",
  smallScreen: "576px",
  extraSmallScreen: "330px",
};
